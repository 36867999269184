function lotteryFormat(number, type, length) {
    let dataArr = [];
    let arrToNumber = null;
    if(type === "two_change" || type === "2HT99") { // เลขกลับสองตัว
        let numberDefault = number[0] + "" + number[1];
        let numberChange = number[1] + "" + number[0];
        
        dataArr = [numberDefault, numberChange];
    }
    else if(type === "three_change" || type === "3HT99") { // เลขกลับสามตัว
        let numberChangeOne = number[0] + "" + number[1] + "" + number[2];
        let numberChangeTwo = number[0] + "" + number[2] + "" + number[1];
        let numberChangeThree = number[1] + "" + number[0] + "" + number[2];
        let numberChangeFour = number[1] + "" + number[2] + "" + number[0];
        let numberChangeFive = number[2] + "" + number[0] + "" + number[1];
        let numberChangeSix = number[2] + "" + number[1] + "" + number[0];
        
        dataArr = [numberChangeOne, numberChangeTwo, numberChangeThree, numberChangeFour, numberChangeFive, numberChangeSix];
    }
    else if(type === "root_front" || type === "1HT20") { // เลขรูดหน้า
        let root_front = [];
        let arrToNumber = number[0];

        for(var i = 0; i < 10; i++) {
            root_front = [...root_front, arrToNumber + "" + i];
        }

        dataArr = root_front;
    }
    else if(type === "root_back" || type === "1HT21") { // เลขรูดหลัง
        let root_back = [];
        arrToNumber = number[0];

        for(var i = 0; i < 10; i++) {
            root_back = [...root_back, i + "" + arrToNumber];
        }

        dataArr = root_back;
    }
    else if(type === "19dor" || type === "1HT19") { // 19ประตู
        let root_front = [];
        let root_back = [];
        let data19Dor = [];
        arrToNumber = number[0];

        for(var i = 0; i < 10; i++) {
            root_front = [...root_front, arrToNumber + "" + i];
        }

        for(var i = 0; i < 10; i++) {
            root_back = [...root_back, i + "" + arrToNumber];
        }

        data19Dor = [...root_front, ...root_back];
        dataArr = data19Dor.filter((item, i, ar) => ar.indexOf(item) === i);
    }
    else if(type === "double" || type === "0HT01") { // เลขเบิ้ล
        let double = ["00", "11", "22", "33", "44", "55", "66", "77", "88", "99"];
        dataArr = double;
        
        // let double = 0;
        // arrToNumber = number[0];

        // double = arrToNumber + "" + arrToNumber;

        // dataArr = [double];
    }
    else if(type === "double_low" || type === "0HT02") { // เลขสองตัวต่ำ
        let double_low = [];
        for(var i = 0; i < 5; i++) {
            for(var i2 = 0; i2 < 10; i2++) {
                double_low = [...double_low, i + "" + i2];
            }
        }

        dataArr = double_low;
    }
    else if(type === "double_hight" || type === "0HT03") { // เลขสองตัวสูง
        let double_hight = [];
        for(var i = 5; i < 10; i++) {
            for(var i2 = 0; i2 < 10; i2++) {
                double_hight = [...double_hight, i + "" + i2];
            }
        }

        dataArr = double_hight;
    }
    else if(type === "two_even" || type === "0HT05") { // เลขสองตัวคี่
        let two_even = [];
        let two_number = 0;
        for(var i = 0; i < 10; i++) {
            for(var i2 = 0; i2 < 10; i2++) {
                two_number = Number(i + "" + i2);
                if(two_number % 2 === 0) {
                    two_even = [...two_even, i + "" + i2];
                }
            }
        }

        dataArr = two_even;
    }
    else if(type === "two_odd" || type === "0HT04") { // เลขสองตัวคู่
        let two_odd = [];
        let two_number = 0;
        for(var i = 0; i < 10; i++) {
            for(var i2 = 0; i2 < 10; i2++) {
                two_number = Number(i + "" + i2);
                if(two_number % 2 !== 0) {
                    two_odd = [...two_odd, i + "" + i2];
                }
            }
        }

        dataArr = two_odd;
    }
    else {
        let arrToText = "";
        let countNumber = number.length;

        if(countNumber <= 2) {
            let dataNumber =  number.slice(Math.max(number.length - length, 0));
            dataNumber.forEach(item => {
                arrToText = arrToText + "" + item;
            });

            dataArr = [arrToText];
        }
        else {
            if(length === 2 || length === 1) {
                let dataNumber =  number.slice(Math.max(number.length - length, 1));
                dataNumber.forEach(item => {
                    arrToText = arrToText + "" + item;
                });

                dataArr = [arrToText];
            }
            else {
                number.forEach(item => {
                    arrToText = arrToText + "" + item;
                });
            }
        }

        dataArr = [arrToText];
    }

    return dataArr;
}

export default lotteryFormat;