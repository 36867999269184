import { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// CSS
import styles from "assets/css/Style.module.css";
import ListYiki from './List';

export default function ContentYiki() {
    const processing = useSelector(state => state.reduxHome.processing);
    const reduxHome = useSelector(state => state.reduxHome.result);
    const [homeData, setHomeData] = useState(null);
    const [yikiData, setYikiData] = useState(null);

    useEffect(() => {
        setHomeData(reduxHome);
    }, [processing, reduxHome])

    useEffect(() => {
        if (homeData != null) {
            let yiki = homeData[2].lottery
            let yikiD = [...yiki]
            setYikiData(yikiD.sort((a,b) => b.status - a.status))
        }
    }, [processing, homeData])


    return (
        <>
            <div className={styles.boxMarginTop}>
                {
                    yikiData !== null && (
                        <div className={styles.boxMediumMarginBottom}>
                            <div className={styles.boxMediumMarginTop}>
                                <ListYiki category_name={"หวยยี่กี"} dataList={yikiData}></ListYiki>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}