import { useEffect } from 'react';

// @Utility
import setBodyScroll from "@utility/app/setBodyScroll";

// Component
import ImageShow from "views/components/ImageShow";

//  CSS
import modalStyles from "assets/css/Modal.module.css";

export default function ModalAlert({ alertData, show, onShow, children }) {
    const close = () => {
        if(alertData.opacityClose) {
            onShow(false);
        }
    };

    useEffect(() => {
        setBodyScroll(show);
    }, [show]);

    return (
        show ?
            <>
                <div id="modal">
                    <div className={modalStyles.contentPosition}>
                        <div className={modalStyles.boxBody}>
                            <div className={modalStyles.box}>
                                <div className={modalStyles.closeButton} onClick={() => onShow(false)}>
                                    <ImageShow className={modalStyles.closeImg} typeUrl="internal" src="images/btn_close_circle.webp"></ImageShow>
                                </div> 

                                <div className={[modalStyles.boxContent, modalStyles.boxContentAlert].join(" ")}>
                                    {
                                        children
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {
                        alertData.opacity && (
                            <div className={modalStyles.fixedBackdrop} onClick={close}></div>
                        )
                    }
                </div>
            </>
            :
            null
    );
}