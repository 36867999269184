import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';

// Component
import Grid from '@mui/material/Grid';
import WrapperContainer from "views/components/WrapperContainer";

// CSS
import navbarStyle from "assets/css/Navbar.module.css";

// Icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function NavbarSub() {
    const navbarSubData = useSelector(state => state.reduxAppNavbarSub.result);
    const reduxAppSetting = useSelector(state => state.reduxAppSetting);
    const history = useNavigate();

    const gotoPage = () => {
        history(`${navbarSubData.redirect}?token=${reduxAppSetting.token}&app_id=${reduxAppSetting.app_id}&code=${reduxAppSetting.code}&language=${reduxAppSetting.lang}&redirect_url=${reduxAppSetting.redirect}`);
    };

    return (
        navbarSubData !== null && (
            <div className={navbarStyle.navbarSub}>
                {
                    navbarSubData.typeContent === "navbarBack" ?
                    <WrapperContainer>
                        <Grid container alignItems="center" className={navbarStyle.navbarSubHeigth}>
                            <Grid item xs={3}>
                                <div className={navbarStyle.cursorBack} onClick={gotoPage}>
                                    <span className={navbarStyle.cursorBackAlign}>
                                        <ArrowBackIcon className={navbarStyle.backIcon}></ArrowBackIcon> 
                                    </span>

                                    ย้อนกลับ
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="center">
                                    <div className={navbarStyle.navText} style={{ marginTop: "-2px" }}>
                                        {
                                            navbarSubData.textContent
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>
                    </WrapperContainer>
                    :
                    <Grid container alignItems="center" justifyContent="center" className={navbarStyle.navbarSubHeigth}>
                        <Grid item>
                            <div className={navbarStyle.navText}>
                                {
                                    navbarSubData.textContent
                                }
                            </div>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    );
}