import { useEffect, useState } from 'react';
import moment from 'moment';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { loadHomeCode } from "@core/redux/actions/home/index";

// @Utility
import timeZone from "@utility/app/timeZone";

// Component
import Grid from '@mui/material/Grid';
import CardImage from "views/pages/home/component/CardImage";

// CSS
// import homeStyle from "views/pages/home/assets/Home.module.css";

export default function List({ category_name, dataList }) {
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const reduxHome = useSelector(state => state.reduxHome.result);
    const [dataArr, setDataArr] = useState(null);
    const [checkTimeout, setCheckTimeout] = useState(null);
    const dispatch = useDispatch();

    const checkYear = (date) => {
        if(timeZone(date, "year") < 2022) {
            return false;
        }
        else {
            return true;
        }
    };
    
    useEffect(() => {
        if(dataList !== null) {
            let datetimeNow = timeZone(moment(), "timestamp");
            let getData = dataList.filter((item) => ((item.status === 1 && checkYear(item.bet_start) && (datetimeNow <= timeZone(item.bet_start, "timestamp")))));
            setDataArr(getData);
        }
    }, [dataList]);

    useEffect(() => {
        if(dataArr !== null) {
            if(dataArr.length > 0) {
                const checkTime = setInterval(() => {
                    dataArr.forEach(element => {
                        let datetimeNow = timeZone(moment(), "timestamp");
                        let datetimeStart = timeZone(element.bet_start, "timestamp");
                        if(datetimeNow >= datetimeStart) {
                            dispatch(loadHomeCode(element.code, reduxHome, category_name, reduxAuth?.token));
                            clearInterval(checkTime);
                        }
                    });
                }, 10000);

                setCheckTimeout(checkTime);
    
                return () => {
                    clearInterval(checkTime);
                }
            }
            else {
                if(checkTimeout !== null) {
                    clearInterval(checkTimeout);
                }
            }
        }
    }, [dataArr, dispatch]);

    return (
        <Grid container spacing={2}>
            {
                dataList.map((item, index) => (
                    <Grid item xs={6} sm={4} md={4} key={index}>
                        <CardImage dataJson={item}></CardImage>
                    </Grid>
                ))
            }
        </Grid>
    );
}