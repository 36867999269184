import { useState, useEffect } from 'react';

// Component
import Table from "views/pages/order-list/component/Table";
import ListGrid from "views/pages/order-list/component/ListGrid";

export default function ReportNotReleased({ isLargeScreen, reduxOrderList, setCountRow, setResultModal, setNameTitle, alertData, setAlertData, loadData }) {
    const [headData, setHeadData] = useState([]);
    const [bodyData, setBodyData] = useState([]);

    useEffect(() => {
        setHeadData([
            { text: "รายการ", align: "left", width: "20%" },
            { text: "วันที่/เวลา", align: "left", width: "19%" },
            { text: "เงินเดิมพัน", align: "right", width: "16%" },
            { text: "สถานะ", align: "center", width: "16%" },
            { text: "ผลชนะ", align: "right", width: "16%" },
            { text: "ดูโพย", align: "center", width: "12%" }
        ]);

        if(reduxOrderList !== null) {
            if(reduxOrderList.data !== null) {
                let dataArrNew = reduxOrderList.data.filter((item) => (item.status === 5 || item.status === 6 || item.status === 7));
                setBodyData(dataArrNew);
                setCountRow(dataArrNew.length);
            }
        }
    }, [reduxOrderList]);

    return (
        // isLargeScreen ?
        // <Table 
        //     headData={headData} 
        //     bodyData={bodyData} 
        //     typeTable="released" 
        //     setResultModal={setResultModal} 
        //     setNameTitle={setNameTitle} 
        //     alertData={alertData} 
        //     setAlertData={setAlertData} 
        //     loadData={loadData}
        // ></Table>
        // :
        <ListGrid 
            typeTable="released" 
            bodyData={bodyData} 
            setResultModal={setResultModal} 
            setNameTitle={setNameTitle} 
            alertData={alertData} 
            setAlertData={setAlertData} 
            loadData={loadData}
            isLargeScreen={isLargeScreen}
        ></ListGrid>
    );
}