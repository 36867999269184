import { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Routes page
import HomeApp from "./pages/home/index";
import LottoryApp from "./pages/lottory/index";
import BasketApp from "./pages/basket/index";
import OrderListApp from "./pages/order-list/index";
import RewardApp from "./pages/reward/index";
import YikiApp from './pages/yiki/index';

const ViewsRoutes = () => {
    const reduxAppSetting = useSelector(state => state.reduxAppSetting);

    const routesRouter = () => {
        return (
            <Suspense fallback={null}>
                <Routes>
                    <Route path="/reward" element={<RewardApp />} exact />
                    <Route path="/order-list" element={<OrderListApp />} exact />
                    <Route path="/basket" element={<BasketApp />} exact />
                    <Route path="/lottory/:code" element={<LottoryApp />} exact />
                    <Route path="/yiki" element={<YikiApp />} exact />
                    <Route path="/" element={<HomeApp />} exact />
                    <Route path="*" element={<Navigate replace to={`/?token=${reduxAppSetting.token}&app_id=${reduxAppSetting.app_id}&code=${reduxAppSetting.code}&language=${reduxAppSetting.lang}&redirect_url=${reduxAppSetting.redirect}`} />} />
                </Routes>
            </Suspense>
        );
    };

    return routesRouter();
};

export default ViewsRoutes;