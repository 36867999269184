import { 
    SET_DATA_ORDER_LIST,
    SET_RESULT_NUMBER
} from "@core/redux/actions/orderList/index";

const initialState = {
    result: null,
    resultNumber: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_ORDER_LIST:
            return { ...state, result: action.payload.items };
        case SET_RESULT_NUMBER:
            return { ...state, resultNumber: action.payload.items };
        default:
            return state;
    }
}