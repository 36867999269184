import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';

// Component
import ImageShow from "views/components/ImageShow";
import Grid from '@mui/material/Grid';

// Icon
import ArticleIcon from '@mui/icons-material/Article';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';

// CSS
import homeStyle from "views/pages/home/assets/Home.module.css";

export default function Menu() {
    const reduxAppSetting = useSelector(state => state.reduxAppSetting);
    const history = useNavigate();

    const gotoPage = (page) => {
        history(`${page}?token=${reduxAppSetting.token}&app_id=${reduxAppSetting.app_id}&code=${reduxAppSetting.code}&language=${reduxAppSetting.lang}&redirect_url=${reduxAppSetting.redirect}`);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={4} sm={3} md={3}>
                <div className={[homeStyle.boxMenu, "center"].join(" ")} onClick={() => gotoPage("/reward")}>
                    {/* <ImageShow className={homeStyle.menuIcon} typeUrl="internal" src="images/AwardsRed.webp"></ImageShow> */}
                    <MilitaryTechIcon className={homeStyle.menuIcon}></MilitaryTechIcon>

                    <div className={[homeStyle.menuMargin, homeStyle.menuText].join(" ")}>
                        ผลรางวัล
                    </div>
                </div>
            </Grid>
            <Grid item xs={4} sm={3} md={3}>
                <div className={[homeStyle.boxMenu, "center"].join(" ")} onClick={() => gotoPage("/order-list")}>
                    {/* <ImageShow  typeUrl="internal" src="images/lotteryRed.webp"></ImageShow> */}
                    <ArticleIcon className={homeStyle.menuIcon}></ArticleIcon>

                    <div className={[homeStyle.menuMargin, homeStyle.menuText].join(" ")}>
                        รายการโพย
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}