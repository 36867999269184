const currencyFormat = (number, digit) => {
    if (number !== null && number !== undefined) {
        return number.toLocaleString(undefined,
            {
                minimumFractionDigits: digit,
                maximumFractionDigits: digit
            })
    } else {
        return 0;
    }
}

export default currencyFormat;