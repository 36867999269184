import { useEffect, useState } from "react";

// @Utility
import currencyFormat from "@utility/numbers/currencyFormat";
import validateInput from "@utility/app/validateInput";

// Redux
import { useDispatch } from 'react-redux';
import { setBasketData } from "@core/redux/actions/basket/index";

// Component
import Grid from '@mui/material/Grid';

// Icon
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// CSS
import formStyles from "assets/css/Input.module.css";
import basketStyle from "views/pages/basket/assets/Basket.module.css";

export default function Navbar({ lottoryData, setDataUpdate, dataLottoryList, duplicateStatus, alertTxt }) {
    const [dataList, setDataList] = useState(null);
    // const [lottoryDataNew, setLottoryDataNew] = useState(null);
    // const [numberInput, setNumberInput] = useState(0);
    const dispatch = useDispatch();

    const handleInput = (event, { key, max, name, number }) => {
        let bet = event.target.value;
        let dataArr = lottoryData;

        if (bet > max) {
            alertTxt("", false, "", "");
            alertTxt("แจ้งเตือน", true, "warning", `เลข ${number} ${name}ใส่ราคาได้ไม่เกิน ${max} บาท`);
            setNumberOnArr(max, key, dataArr);
        }
        else {
            setNumberOnArr(bet, key, dataArr);
        }
    };

    const setNumberOnArr = (bet, key, dataArr) => {
        const index = dataArr.findIndex(item => item.key === key);
        if (bet === "") {
            dataArr[index].bet = "";
        }
        else {
            dataArr[index].bet = Number(bet);
        }

        // console.log(dataArr)
        // setLottoryDataNew(dataArr);
        dispatch(setBasketData(dataArr));
        reloadData();
    };

    const deleteLottory = (key) => {
        let dataArr = lottoryData;
        let dataArrNew = dataArr.filter((item) => item.key !== key);

        // setLottoryDataNew(dataArrNew);
        dispatch(setBasketData(dataArrNew));
        reloadData();
    };

    const reloadData = () => {
        let groupHead = []; // หาข้อมูลส่วนหัวของหวย
        let groupData = []; // เก็บข้อมูลจัด Group
        let lottoryData = dataLottoryList;
        lottoryData.forEach(element => { // วนลูบหาส่วนหัวของหวย
            groupHead = [...groupHead, { code: element.code, name: element.name }]
        });

        groupHead = groupHead.filter((value, index, self) => // ลบค่าซ้ำออก
            index === self.findIndex((t) => (
                t.code === value.code
            ))
        );

        groupHead.forEach((element) => { // จัด Group ข้อมูลหวย
            let dataFilter = lottoryData.filter((item) => item.code === element.code);
            groupData = [...groupData, { ...element, data: dataFilter }];
        });

        setDataList(groupData);
    };

    const checkWarning = (item) => {
        if (item.is_cancel === true || item.is_max === true || item.is_max_number === true || item.is_min === true || item.is_price_rate === true) {
            return true;
        }
        else {
            return false;
        }
    };

    // const checkDuplicateData = (item) => {
    //     let duplicateData = duplicateList.find((element) => (element.code === item.code && element.number === item.number));

    //     if(duplicateData === undefined) {
    //         return false;
    //     }
    //     else {
    //         return true;
    //     }
    // };

    useEffect(() => {
        if (dataLottoryList !== null) {
            // setDataLottoryList(lottoryData);
            reloadData();
        }
    }, [dataLottoryList]);

    // useEffect(() => {
    //     if(lottoryDataNew !== null) {
    //         setDataUpdate(numberInput);
    //         // setDataLottoryList(lottoryDataNew);
    //         dispatch(setBasketData(lottoryDataNew));
    //     }
    // }, [dispatch]);

    return (
        <div className={basketStyle.boxList}>
            <div className={basketStyle.listHeight}>
                {
                    dataList !== null && (
                        dataList.map((item) => (
                            <div className={basketStyle.listMarginBottom} key={item.code}>
                                <div className={basketStyle.listTitle}>
                                    {
                                        `${item.name} `
                                    }

                                    {(duplicateStatus) ? " (ดูเลขซ้ำ)" : ""}
                                    <span className="label labelInfo">เริ่มต้น <span className="textBoldHight">1</span> บาท - สูงสุด <span className="textBoldHight">100</span> บาท</span>
                                </div>
                                {
                                    item.data.map((item2) => (
                                        // <div className={duplicateStatus ? duplicateList.length > 0 ? checkDuplicateData(item2) ? basketStyle.test : basketStyle.lottoryMarginBottom : basketStyle.lottoryMarginBottom : basketStyle.lottoryMarginBottom} key={item2.key}>
                                        <div className={basketStyle.lottoryMarginBottom} key={item2.key}>
                                            <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                                <Grid item xs={10} md={11}>
                                                    <div className={[basketStyle.listElement, checkWarning(item2) ? basketStyle.warningBorder : null].join(" ")}>
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={2} sm={2} className={[basketStyle.elementLottoryNumber, basketStyle.elementPaddingFirst, basketStyle.elementPaddingFirstMobile].join(" ")}>
                                                                <div className="center textBoldHight">
                                                                    {
                                                                        item2.number
                                                                    }
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={4} sm={4} md={4} className={[basketStyle.elementBorderRight, basketStyle.elementPaddingFirst].join(" ")}>
                                                                <input type="text" max={item2.max} value={item2.bet} className={[formStyles.inputStyles, basketStyle.inputStyles, "Sarabun"].join(" ")} placeholder="ระบุจำนวนเงิน" onKeyPress={(event) => validateInput(event, "number")} onChange={(e) => handleInput(e, item2)} required />
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} md={6} className={basketStyle.elementPadding}>
                                                                <Grid container alignItems="center" justifyContent="space-between">
                                                                    <Grid item xs={7}>
                                                                        <div style={{ marginLeft: "5px" }}>
                                                                            <div className={basketStyle.elementResult} style={{ display: "inline-block" }}>
                                                                                ชนะ {Number(item2.price) * Number(item2.bet)}
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <div className={basketStyle.elementRatePay}>
                                                                            {currencyFormat(item2.price, 2)} ฿
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2} md={1}>
                                                    <div className={[basketStyle.listDelete, "center"].join(" ")} onClick={() => deleteLottory(item2.key)}>
                                                        <DeleteForeverIcon className={basketStyle.listDeleteIcon}></DeleteForeverIcon>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    )
                }
            </div>
        </div>
    );
}