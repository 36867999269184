import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// @Utility
import setBodyScroll from "@utility/app/setBodyScroll";

// Component
// import ImageShow from "views/components/ImageShow";

//  CSS
import modalStyles from "assets/css/Modal.module.css";

// Icon
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

export default function ModalFull({ show, onShow, children, bgClass }) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const close = () => {
        onShow(false);
    };

    useEffect(() => {
        setBodyScroll(show);
    }, [show]);

    return (
        show ?
            <>
                <div id="modal">
                    <div className={modalStyles.contentPosition}>
                        <div className={modalStyles.boxBodyFull}>
                            <div className={modalStyles.boxFull} style={{ border: "0px" }}>
                                {
                                    isLargeScreen && (
                                        <div className="relative">
                                            <div className={modalStyles.closeButtonFull} onClick={() => close(false)}>
                                                <DoNotDisturbOnIcon className={modalStyles.iconImgFull}></DoNotDisturbOnIcon>
                                            </div>
                                        </div> 
                                    )
                                }

                                <div className={modalStyles.boxContentFull} style={{ padding: "0px",  background: bgClass !== 0 ?  "var(--bg-floor)" : "var(--bg-card)"  }}>
                                    {
                                        children
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className={modalStyles.fixedBackdrop} onClick={close}></div>
                </div>
            </>
            :
            null
    );
}