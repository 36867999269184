import { useState, useEffect } from 'react';

export default function ImageShow({ className, style, typeUrl, src }) {
    const [urlImage, setUrlImage] = useState("");

    useEffect(() => {
        if(typeUrl === "internal") {
            setUrlImage(`${window.location.origin}/${src}`);
        }
        else {
            setUrlImage(src);
        }
    }, [typeUrl, src]);

    return (
        urlImage !== "" && (
            <img className={className} style={style} src={urlImage} alt="หวยออนไลน์"/>
        )
    );
}