import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// @Utility
import timeZone from "@utility/app/timeZone";
import dateFormatThai from "@utility/app/dateFormatThai";

// Component
import Grid from '@mui/material/Grid';

import { checkCloseRound, clearTimeCheck } from '@core/redux/actions/lottory/timecheck';
// import { loadYikiShootReward } from '@core/redux/actions/yiki/index';
// import { apiServices } from '@core/services/appService';

// Icon
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// CSS
import styles from "assets/css/Style.module.css";
import lottoryStyle from "views/pages/lottory/assets/Lottory.module.css";


export default function TimeOut({ lottoryData: dataList, checkRound, statusOpen, roundStatus }) {
    // const processing = useSelector(state => state.reduxHome.processing);
    const [timeShow, setTimeShow] = useState(0);
    const [checkingTime, setCheckingTime] = useState();
    const [storeSeconds, setStoreSeconds] = useState();
    // const reduxTimecheck = useSelector((state) => state.reduxTimecheck.result);
    // const reduxYiki = useSelector((state) => state.reduxYiki.result);
    // const reduxAuthData = useSelector((state) => state.reduxAuth.result);
    // const reduxLottoryData = useSelector((state) => state.reduxLottory.result);
    // const reduxAuth = { ...reduxAuthData, ...reduxLottoryData };
    const dispatch = useDispatch();
    const [lottoryData, setLottoryData] = useState(null);

    const secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        setTimeShow(`${hours} : ${minutes} : ${seconds}`);
    };

    // useEffect(() => {
    //     if (lottoryData !== null) {
    //         setLtrData(lottoryData);
    //     }
    // }, [processing, reduxAuth]);

    // console.log(ltrData)
    // console.log(reduxYiki)

    // useEffect(() => {
    //     if (ltrData !== null || ltrData !== undefined) {
    //         console.log(ltrData) 
    //         setResultId(lottoryData.result_id);
            
    //         if (resultId !== null || resultId !== 0 || resultId !== undefined) {
    //             console.log(resultId);
                // dispatch(loadYikiShootReward(resultId, reduxAuth?.token));
                // if (reduxYiki !== null || reduxYiki !== undefined) {
                    // console.log(reduxYiki)
                // }
    //         }
    //     }
        
    // }, [processing, lottoryData]);

    // console.log(lottoryData)
    // console.log(lottoryData.code.split("").slice(0, 3).join(""))

    // const lottoCode = lottoryData.code.split("").slice(0, 3).join("");

    const renderTimeText = (statusOpen, lottoryData) => {
        if(statusOpen) {
            return (
                <div className="right">
                    ปิดรับ เวลา { timeZone(lottoryData?.bet_end, "time") } น.
                </div>
            );
        }
        else if(statusOpen === false) {
            return (
                <div className="right">
                    เปิดรับ { dateFormatThai(timeZone(lottoryData?.bet_start, "date")) }
                </div>
            );
        }
        else {
            return (
                <div className="right">
                    ปิดรับแทงหวย
                </div>
            );
        }
    };

    const renderRoundStatus = (roundStatus) => {
        if (roundStatus === 0) {
            return "ปิดรอบหวย";
        } else if (roundStatus === 1) {
            return "ปิดใช้งาน";
        } else if (roundStatus === 6) {
            return "ยกเลิก";
        } else if (roundStatus === 7) {
            return "ยกเลิกเดิมพัน";
        } else if (roundStatus === 8) {
            return "ยกเลิกผลลลัพธ์";
        }
    };

    useEffect(() => {
        if(lottoryData !== null) {
            if(checkRound !== 0 && timeShow >= 0) {
                let dateEnd = timeZone(statusOpen ? lottoryData?.bet_end : lottoryData?.bet_start, "noFormat");
                let dateCurrent = timeZone(moment(), "noFormat");
                let duration = moment.duration(dateEnd.diff(dateCurrent));
                let seconds = duration.asSeconds();

                setCheckingTime(setInterval(() => {
                    --seconds;
                    if(seconds <= 0) {
                        clearInterval(checkingTime);
                        setTimeShow(0);
                    } else {
                        secondsToTime(seconds);
                        setStoreSeconds(seconds);
                    }
                }, 1000));

                return () => {
                    clearInterval(checkingTime);
                }
            }
        }
    }, [lottoryData, checkRound, statusOpen]);

    useEffect(() => {
        if (storeSeconds < 1 ) {
            // console.log("set")
            dispatch(checkCloseRound(storeSeconds));
        } else if (storeSeconds > 3) {
            dispatch(clearTimeCheck());
        }
    } ,[storeSeconds, dispatch, lottoryData]);

    useEffect(() => {
        setLottoryData(null);

        if(dataList !== null) {
            setLottoryData(dataList);
        }
    }, [dataList])

    return (
        lottoryData !== null && (
            <div className={lottoryStyle.boxTimeout}>
                {
                    checkRound === 0  ?
                    <button className={[lottoryStyle.borderTimeout, lottoryStyle.borderTimeoutSize, "center", "textCLDefult"].join(" ")}>
                        <span className="textBoldLow textCLDanger">
                            {
                                // lottoCode === "HYK" ?
                                // "ยกเลิกการเดิมพัน"
                                // :
                                "ปิดรอบหวย"
                            }
                            
                        </span>
                    </button>
                    : checkRound !== 0 && (roundStatus === 0 || roundStatus === 1 || roundStatus === 6 || roundStatus === 7 || roundStatus === 8) ?
                    <button className={[lottoryStyle.borderTimeout, lottoryStyle.borderTimeoutSize, "center", "textCLDefult"].join(" ")}>
                        <span className="textBoldLow textCLDanger">
                            {
                                // lottoCode === "HYK" ?
                                // "ยกเลิกการเดิมพัน"
                                // :
                                // "ปิดรอบหวย"
                                renderRoundStatus(roundStatus)
                            }
                            
                        </span>
                    </button>
                    :
                    <Grid container alignItems="center">
                        <Grid item xs={7} sm={7} className="textBoldHight">
                            {
                                statusOpen !== null ? 
                                <span>
                                    ประจำวันที่ { dateFormatThai(timeZone(lottoryData?.result_time, "date")) }
                                </span>
                                :
                                "ปิดรับแทง"
                            }
                        </Grid>
                        <Grid item xs={5} sm={5} className="textBoldHight">
                            {
                                renderTimeText(statusOpen, lottoryData)
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <div className={[styles.boxMediumMarginTop, "center"].join(" ")}>
                                <button className={[lottoryStyle.borderTimeout, lottoryStyle.borderTimeoutSize, "center", "textCLDefult"].join(" ")}>
                                    {
                                        statusOpen ?
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Grid item>
                                                <AccessTimeIcon className={lottoryStyle.iconTimeOutSize}></AccessTimeIcon>
                                            </Grid>
                                            <Grid item>
                                                ปิดรับในอีก 
                                            </Grid>
                                            <Grid item>
                                                <div className="textBoldHight textCLDanger">
                                                    {timeShow <= 0 ? "00:00:00" : timeShow}
                                                    {/* {console.log("name -> ",lottoryData.name)}
                                                    {console.log("start -> ",lottoryData.bet_start)}
                                                    {console.log("end -> ",lottoryData.bet_end)} */}
                                                    {/* <TimeCount dataJson={lottoryData} /> */}
                                                </div>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Grid item>
                                                <AccessTimeIcon className={lottoryStyle.iconTimeOutSize}></AccessTimeIcon>
                                            </Grid>
                                            <Grid item>
                                                เปิดรับในอีก 
                                            </Grid>
                                            <Grid item>
                                                <div className="textBoldHight textCLDanger">
                                                    {timeShow <= 0 ? "00:00:00" : timeShow}
                                                    {/* <TimeCount dataJson={lottoryData} /> */}
                                                </div>
                                            </Grid>
                                        </Grid>  
                                    }
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                }
            </div>
        )
    );
}