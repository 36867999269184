// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";

import { useEffect } from "react";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { loadHomeList } from "@core/redux/actions/home/index";
import { setDataNavbarSub } from "@core/redux/actions/app/navbarSub";

// Component
import ContentYiki from "./component/Content";

const YikiApp = () => {
    document.title = "หน้าหลัก";
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDataNavbarSub({
            typeContent: "navbarBack",
            textContent: "แทงหวยยี่กี",
            redirect: `/`,
            condition: false
        }));

        if(reduxAuth !== null) {
            dispatch(loadHomeList({}, reduxAuth?.token));
        }
    }, [reduxAuth, dispatch]);

    return (
        <ContentYiki></ContentYiki>
    );
}

export default YikiApp;