import { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Component
import Grid from '@mui/material/Grid';
import WrapperContainer from "views/components/WrapperContainer";
import Credit from "views/components/Credit";
import Username from "views/components/Username";
import Condition from "views/components/Condition";

// CSS
import navbarStyle from "assets/css/Navbar.module.css";

export default function Navbar() {
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const reduxLottory = useSelector(state => state.reduxLottory.result);
    const navbarSubData = useSelector(state => state.reduxAppNavbarSub.result);
    const [modalData, setModalData] = useState({
        show: false
    });

    return (
        <>
            {
                modalData.show && (
                    <Condition modalData={modalData} setModalData={setModalData} reduxAuth={{...reduxAuth, ...reduxLottory}}></Condition>
                )
            }

            <div className={[navbarStyle.navbar, navbarStyle.navbarHeight].join(" ")}>
                <WrapperContainer>
                    <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Username reduxAuth={reduxAuth}></Username>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <Credit reduxAuth={reduxAuth}></Credit>
                                </Grid>
                                {
                                    navbarSubData !== null && (
                                        navbarSubData.condition && (
                                            <Grid item>
                                                <div className={navbarStyle.condition} onClick={() => setModalData({ show: true })}>
                                                    กติกา
                                                </div>
                                            </Grid>
                                        )
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </WrapperContainer>
            </div>
        </>
    );
}