// @Utility
import currencyFormat from "@utility/numbers/currencyFormat";

// CSS
import styles from "assets/css/Style.module.css";
import orderListStyle from "views/pages/order-list/assets/OrderList.module.css";
import lottoryStyle from "views/pages/lottory/assets/Lottory.module.css";

export default function Condition({ typeData }) {

    return (
        <div className={[styles.boxContentRadius, lottoryStyle.boxCondition].join(" ")} style={{ padding: "0px" }}>
            <div className={[lottoryStyle.conditionTitle, "textBoldHight"].join(" ")}>
                เงื่อนไขการเเทง
            </div>
            {
                typeData !== null && (
                    <table className={orderListStyle.tableSize}>
                        <thead>
                            <tr>
                                <th className={[orderListStyle.theadSizePopup, lottoryStyle.listTdPaddingPopup, typeData.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "left", width: "25%" }}>ประเภท</th>
                                <th className={[orderListStyle.theadSizePopup, typeData.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "center", width: "20%" }}>เริ่มต้น</th>
                                <th className={[orderListStyle.theadSizePopup, typeData.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "center", width: "25%" }}>สูงสุดต่อครั้ง</th>
                                <th className={[orderListStyle.theadSizePopup, typeData.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "center", width: "25%" }}>สูงสุดต่อเลข</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                typeData.map((item, index) => (
                                    <tr key={index} className={[orderListStyle.tableListPopup, lottoryStyle.tableListPopup, (index % 2 === 0) ? orderListStyle.listGrey : orderListStyle.listDefault].join(" ")}>
                                        <td className={lottoryStyle.listTdPaddingPopup} style={{ textAlign: "left", paddingLeft: "15px" }}>
                                            {
                                                item.name
                                            }
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {
                                                `${currencyFormat(item.min, 0)} ฿`
                                            }
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {
                                                `${currencyFormat(item.max, 0)} ฿`
                                            }
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {
                                                `${currencyFormat(item.max_number, 0)} ฿`
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                )
            }
            <br />
            {/* {
                typeData !== null && (
                    typeData.map((item, index) => (
                        <div className={(typeData.length - 1) === index ? lottoryStyle.conditionList : lottoryStyle.conditionListBorder} key={index}>
                            {
                                item.name 
                            }
                            <div className={lottoryStyle.listMarginTop}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <div className={lottoryStyle.listText}>
                                            ขั้นต่ำต่อครั้ง
                                            <div className={[lottoryStyle.listMarginTop, "center"].join(" ")}>
                                                {
                                                    `${item.min} บาท` 
                                                }
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="center">
                                            <div className={lottoryStyle.listText}>
                                                สูงสุดต่อครั้ง
                                                <div className={[lottoryStyle.listMarginTop, "center"].join(" ")}>
                                                    {
                                                        `${item.max} บาท` 
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="center">
                                            <div className={lottoryStyle.listText}>
                                                สูงสุดต่อเลข
                                                <div className={[lottoryStyle.listMarginTop, "center"].join(" ")}>
                                                    {
                                                        `${item.max_number} บาท` 
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    ))
                )
            } */}
        </div>
    );
}