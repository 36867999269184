import { 
    SET_BASKET_DATA,
} from "@core/redux/actions/basket/index";

const initialState = {
    lottory: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_BASKET_DATA:
            return { ...state, lottory: action.payload.items };
        default:
            return state;
    }
}