import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';

// @Utility
import timeZone from "@utility/app/timeZone";
import dateFormatThai from "@utility/app/dateFormatThai";

// Component
import ImageShow from "views/components/ImageShow";
import TimeCount from 'views/components/TimeCount';

// CSS
import homeStyle from "views/pages/home/assets/Home.module.css";
import buttonStyle from "assets/css/Button.module.css";

export default function CardImage({ dataJson }) {
    const reduxAppSetting = useSelector(state => state.reduxAppSetting);
    const history = useNavigate();
    const d = new Date();
    let year = d.getFullYear();

    const gotoLottory = () => {
        // if(dataJson.status === 2) {
            history(`/lottory/${dataJson.code}?token=${reduxAppSetting.token}&app_id=${reduxAppSetting.app_id}&code=${reduxAppSetting.code}&language=${reduxAppSetting.lang}&redirect_url=${reduxAppSetting.redirect}`)
        // }
    };

    const checkYear = (date) => {
        if(timeZone(date, "year") < year) {
            return false;
        }
        else {
            return true;
        }
    };

    return (
        <div className={[homeStyle.lottoCard, homeStyle.lottoCardActive].join(" ")} onClick={gotoLottory} style={{ opacity: dataJson.status === 2 ? "1" : "0.6" }}>
            <div className={homeStyle.lottoCardImage}>
                {/* lotto_example */}
                <div className="relative">
                    {
                        dataJson?.background === "" ? 
                        <ImageShow className={homeStyle.lottoImg} typeUrl="internal" src="/images/lotto_example.png"></ImageShow>
                        :
                        <ImageShow className={homeStyle.lottoImg} typeUrl="external" src={dataJson?.background}></ImageShow>
                        
                    }
                    
                    <div className={homeStyle.lottoCardImagePosition}>
                        <ImageShow className={homeStyle.lottoCountryImg} typeUrl="external" src={dataJson?.image}></ImageShow>
                        
                        <div className={[homeStyle.lottoCardTitle, homeStyle.menuMargin].join(" ")}>
                            {
                                dataJson.name
                            }
                        </div>

                        <div className={[homeStyle.lottoCardText, homeStyle.menuMargin].join(" ")}>
                            {
                                dataJson.status === 2 ?
                                `ปิดรับ : ${ dateFormatThai(timeZone(dataJson.bet_end, "date")) } ${ timeZone(dataJson.bet_end, "time") } น.`
                                :
                                checkYear(dataJson.bet_start) ?
                                    `${ dateFormatThai(timeZone(dataJson.bet_start, "date")) } ${ timeZone(dataJson.bet_start, "time") } น.`
                                    :
                                    'ปิดรับ : รอรอบถัดไป '
                                
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={[homeStyle.lottoCardContent, "center"].join(" ")}>
                {
                    dataJson.status === 2 ?
                    <div className={[buttonStyle.btnOutLine, buttonStyle.outLineSuccess, homeStyle.btnFontSize].join(" ")}>
                        <TimeCount dataJson={ dataJson } />
                    </div>
                    :
                    <div className={[buttonStyle.btnOutLine, buttonStyle.outLineInfo, homeStyle.btnFontSize].join(" ")}>
                        {/* {
                            dataJson.status_name
                        } */}
                        ยังไม่รับแทง
                    </div>
                }
            </div>
        </div>
    );
}