import { useEffect } from 'react';

// Routes
import { BrowserRouter as Router } from 'react-router-dom';
import ViewsRoutes from "./ViewsRoutes";

// Redux
import { useSelector } from 'react-redux';

// Component
import Navbar from "views/components/Navbar";
import NavbarSub from "views/components/NavbarSub";
import WrapperContainer from "views/components/WrapperContainer";
import Loadding from "views/components/Loadding";
import ErrorApp from "views/pages/error/index";

const Layouts = () => {
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const reduxAppLoadding = useSelector(state => state.reduxAppLoadding);

    const colorCms = { 
        "bg-brand": "#002BC2",
        "bg-sucess": "#00B907",
        "bg-danger": "#E10000",
        "bg-black": "#000000",
        "bg-grey": "#1E2330",
        "bg-floor": "#D5D5D5",
        "bg-line": "#C3C4C6",
        "bg-dark-gray": "#707070",
        "bg-white": "#FFFFFF",
        "bg-secondary": "#E6E8EB",
        "bg-card": "#FFFFFF",
        "bg-warning": "#FFE600",
        "bg-blue": "#001689"
    };

    const setLoadDataCms = (dataCMS) => {
        localStorage.setItem("dataCMS", JSON.stringify(dataCMS));
        document.getElementsByTagName("body")[0].style.background = dataCMS["bg-floor"];
        document.documentElement.style.setProperty("--bg-black", dataCMS["bg-black"]);
        document.documentElement.style.setProperty("--bg-blue", dataCMS["bg-blue"]);
        document.documentElement.style.setProperty("--bg-brand", dataCMS["bg-brand"]);
        document.documentElement.style.setProperty("--bg-card", dataCMS["bg-card"]);
        document.documentElement.style.setProperty("--bg-danger", dataCMS["bg-danger"]);
        document.documentElement.style.setProperty("--bg-dark-gray", dataCMS["bg-dark-gray"]);
        document.documentElement.style.setProperty("--bg-floor", dataCMS["bg-floor"]);
        document.documentElement.style.setProperty("--bg-grey", dataCMS["bg-grey"]);
        document.documentElement.style.setProperty("--bg-line", dataCMS["bg-line"]);
        document.documentElement.style.setProperty("--bg-secondary", dataCMS["bg-secondary"]);
        document.documentElement.style.setProperty("--bg-sucess", dataCMS["bg-sucess"]);
        document.documentElement.style.setProperty("--bg-warning", dataCMS["bg-warning"]);
        document.documentElement.style.setProperty("--bg-white", dataCMS["bg-white"]);
    };
    
    useEffect(() => {
        if(reduxAuth?.error === undefined) {
            if (reduxAuth !== null && reduxAuth !== undefined) {
                if (reduxAuth.cms !== null && Object.keys(reduxAuth.cms).length > 1) {
                    localStorage.removeItem("dataCMS");
                    const dataCMS = reduxAuth.cms;
                    setLoadDataCms(dataCMS);
                    // console.log(dataCMS);
                } else {
                    localStorage.setItem("dataCMS", JSON.stringify(colorCms));
                    let dataCMS = JSON.parse(localStorage.getItem("dataCMS"));
                    if (dataCMS !== undefined && dataCMS !== null) {
                        setLoadDataCms(dataCMS);
                    } else {
                        return
                    }
                }
            }    
        }
    }, [reduxAuth]);

    return (
        reduxAuth !== null && (
            reduxAuth?.error === undefined ?
            <Router>
                {
                    reduxAppLoadding.show && (
                        <Loadding textProcess={reduxAppLoadding.textProcess}></Loadding>
                    )
                }
                <NavbarSub></NavbarSub>
                            
                {
                    reduxAuth !== undefined && (
                        <Navbar></Navbar>
                    )
                } 

                <WrapperContainer>
                    <div className="wrapper">
                        <ViewsRoutes></ViewsRoutes>
                    </div>
                </WrapperContainer>
            </Router>
            :
            <ErrorApp reduxAuth={reduxAuth.resData}></ErrorApp>
        )
    );
}

export default Layouts;