import { useLocation } from "react-router";

// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { loadOrderList } from "@core/redux/actions/orderList/index";
import { setDataNavbarSub } from "@core/redux/actions/app/navbarSub";

// Component
import ContentOrderList from "./component/Content";

const OrderListApp = () => {
    window.scrollTo(0, 0);
    // const reduxOrderList = useSelector(state => state.reduxOrderList.result);
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const dispatch = useDispatch();
    const location = useLocation();

    const loadData = (limit = 10, offset = -1) => {
        let myJson = {
            "filter": {
                "loterry_id": 1,
                "round_id": 1,
                "ticket": "-1",
                "status": -1
            },
            "datatable": {
                "limit": limit,
                "offset": offset,
                "sort": ""
            }
        };

        dispatch(loadOrderList(myJson, reduxAuth?.token));
    };

    useEffectOnce(() => {
        dispatch(setDataNavbarSub({
            typeContent: "navbarBack",
            textContent: "รายการโพย",
            redirect: "/",
            condition: false
        }));

        if(reduxAuth !== null) {
            loadData(10, -1);
        }
    }, [reduxAuth, location]);

    return (
        <ContentOrderList loadData={loadData}></ContentOrderList>
    );
}

export default OrderListApp;