const dateFormatThai = (val) => {
    if(val !== undefined) {
        let dataSplit = val.split("-");
        let day = dataSplit[2];
        let month = dataSplit[1];
        let year = dataSplit[0];

        return `${day} ${monthThai(month)} ${Number(year) + 543}`;
    }
};

const monthThai = (month) => {
    switch (month) {
        case "01": 
            return "ม.ค.";
        case "02": 
            return "ก.พ.";
        case "03": 
            return "มี.ค.";
        case "04": 
            return "เม.ษ.";
        case "05": 
            return "พ.ค.";
        case "06": 
            return "มิ.ย.";
        case "07": 
            return "ก.ค.";
        case "08": 
            return "ส.ค.";
        case "09": 
            return "ก.ย.";
        case "10": 
            return "ต.ค.";
        case "11": 
            return "พ.ย.";
        case "12": 
            return "ธ.ค.";
        default:
            break;
    }
};

export default dateFormatThai;