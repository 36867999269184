import axios from 'axios';
import serviceConfig from "@core/services/serviceConfig";
// import { isToken, getToken } from "@core/auth/utils";
// import { checkStatusCode } from "./services";

const svConfig = { ...serviceConfig };

const apiServices = async (myJson, method, path, token) => {
  let config = {};
  if(token !== null) {
    config = {
      method: method,
      url: path,
      headers: {
        "Authorization": `${svConfig.headerType} ${token}`,
        "Content-Type": svConfig.contentType,
      },
      data: myJson
    }
  }
  else {
    config = {
      method: method,
      url: path,
      headers: { 
        "Content-Type": svConfig.contentType,
      },
      data: myJson
    };
  }

  const dataPromise = await axios(config).then((response) => response.data).catch((e) => e.response.data);

  if(dataPromise !== undefined) {
    const { code, result, message } = dataPromise;
    return { code, result, message };
  }
  else {
    return { code: 99, result: null, message: "" };
  }
};

export {
  apiServices
};