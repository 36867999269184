// Services
import { apiServices } from "@core/services/appService";

const SET_LOAD_HOME_LIST = 'app/SET_LOAD_HOME_LIST';
const SET_LOAD_HOME_PROCESSING = 'app/SET_LOAD_HOME_PROCESSING';

function loadHomeList(myJson, token) {
    return dispatch => {
        apiServices(myJson, "get", "/py/lottery/list", token).then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 1) {
                const result = data.result;
                
                dispatch({
                    type: SET_LOAD_HOME_LIST,
                    payload: {
                        items: result.data
                    }
                })
            }
            else {
                dispatch({
                    type: SET_LOAD_HOME_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}


function loadHomeCode(myJson, oldData, category_name, token) {
    return dispatch => {
        dispatch({
            type: SET_LOAD_HOME_PROCESSING,
            payload: {
                items: true
            }
        });

        apiServices({}, "get", `/py/lottery/list/${myJson}`, token).then((data) => {
            try {
                const statusCode = data.code;

                if (statusCode === 200 || statusCode === 1) {
                    const result = data.result;
                    if (result.data !== null) {
                        const index = oldData.findIndex(item => item.category_name === category_name);
                        const indexLottory = oldData[index].lottery.findIndex(item => item.code === myJson);
                        oldData[index].lottery[indexLottory] = result.data;

                        dispatch({
                            type: SET_LOAD_HOME_LIST,
                            payload: {
                                items: oldData
                            }
                        });

                        dispatch({
                            type: SET_LOAD_HOME_PROCESSING,
                            payload: {
                                items: false
                            }
                        });
                    }
                }
                else {
                    dispatch({
                        type: SET_LOAD_HOME_PROCESSING,
                        payload: {
                            items: false
                        }
                    });
                }
            } catch (error) { }
        });
    }
}

export {
    loadHomeList,
    loadHomeCode,
    SET_LOAD_HOME_LIST,
    SET_LOAD_HOME_PROCESSING
}