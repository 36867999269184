// Redux
import { useSelector } from 'react-redux';

// Component
import Grid from '@mui/material/Grid';

// CSS
import modalStyles from "assets/css/Modal.module.css";
import buttonStyle from "assets/css/Button.module.css";

export default function Token({ reduxAuth }) {
    const reduxAppSetting = useSelector(state => state.reduxAppSetting);

    const redirectBackWeb = () => {
        window.location.href = reduxAppSetting.redirect;
    };

    return (
        <div id="modal">
            <div className={modalStyles.contentPosition}>
                <div className={modalStyles.boxBody}>
                    <div className={modalStyles.box}>
                        <div className={modalStyles.boxContentError}>
                            <div className={[modalStyles.boxTitle, "center"].join(" ")}>
                                มีข้อผิดพลาด
                            </div>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <div className="svg-box">
                                        <svg className="circular red-stroke">
                                            <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10"/>
                                        </svg>
                                        <svg className="cross red-stroke">
                                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                                                <path className="first-line" d="M634.087,300.805L673.361,261.53" fill="none"/>
                                            </g>
                                            <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                                                <path className="second-line" d="M634.087,300.805L673.361,261.53"/>
                                            </g>
                                        </svg>
                                    </div>
                                </Grid>
                            </Grid>

                            <div className={[modalStyles.confirmParagrap, "center"].join(" ")}>
                                { reduxAuth.message }
                            </div>

                            {
                                reduxAuth?.hideButton === undefined && (
                                    <div className={modalStyles.boxMarginFormTop}>
                                        <Grid container spacing={1} justifyContent="center">
                                            <Grid item xs={12}>
                                                <div className="center">
                                                    <button className={[buttonStyle.btn, buttonStyle.btnConfirm, buttonStyle.btnSizeMedium, buttonStyle.btnRadius].join(" ")} style={{ fontWeight: "400" }} onClick={redirectBackWeb}>
                                                        กลับสู่หน้าหลัก
                                                    </button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div> 
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}