// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";

// Hooks
import useParameterUrl from "@utility/hooks/useParameterUrl";

// Component root
import Layouts from "./views/Layouts";

// Redux
import { useDispatch } from 'react-redux';
import { loadDataAuthen } from "@core/redux/actions/auth/index";
import { reloadSetting } from "@core/redux/actions/app/setting";

// CSS Main
import './App.css';

function App() {
  const dispatch = useDispatch();
  const pathUrl = window.location.pathname;
  const parsed = useParameterUrl();
  const { 
    token,
    language,
    app_id,
    code,
    redirect_url
  } = parsed;

  useEffectOnce(() => {
    // localStorage.removeItem("customerToken");
    if(pathUrl !== "/reward") {
      if(token !== undefined && app_id !== undefined && code !== undefined && redirect_url !== undefined) {
        let myJson = {
          "token": token,
          "lang": language,
          "app_id": app_id,
          "redirect": redirect_url,
          "code": code
        };
  
        dispatch(reloadSetting(myJson));
        dispatch(loadDataAuthen(pathUrl, true, myJson));
      }
      else {
        dispatch(loadDataAuthen(pathUrl, false, {}));
      }
    }
    else {
      dispatch(loadDataAuthen(pathUrl, true, {}));
    }
  }, []);

  return <Layouts />;
}

export default App;
