import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';

// Component
import Grid from '@mui/material/Grid';
import WrapperContainer from "views/components/WrapperContainer";

// Icon
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// CSS
import navbarStyle from "assets/css/Navbar.module.css";
import basketStyle from "views/pages/basket/assets/Basket.module.css";

export default function Navbar({ lottoryData }) {
    // const reduxAppSetting = useSelector(state => state.reduxAppSetting);
    const history = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <div className={[basketStyle.navbarPosition, navbarStyle.navbarHeight, basketStyle.navbarFull].join(" ")}>
            <WrapperContainer>
                <Grid container alignItems="center" justifyContent={isLargeScreen ? "center" : "space-between"} className={navbarStyle.navbarHeight}>
                    <Grid item>
                        รายการเเทงทั้งหมด 
                        <span className={[basketStyle.navbarNumber, "textBoldHight textCLWarning"].join(" ")}>
                            { lottoryData !== null ? lottoryData.length : 0 }
                        </span> 
                        รายการ
                    </Grid>
                    {
                        isLargeScreen ? <></>
                        :
                        <Grid item>
                        {/* <RemoveCircleOutlineIcon className={basketStyle.navbarCloseIcon} onClick={() => history(`/?token=${reduxAppSetting.token}&app_id=${reduxAppSetting.app_id}&code=${reduxAppSetting.code}&language=${reduxAppSetting.lang}&redirect_url=${reduxAppSetting.redirect}`)}></RemoveCircleOutlineIcon> */}
                            <RemoveCircleOutlineIcon className={basketStyle.navbarCloseIcon} onClick={() => history(-1)}></RemoveCircleOutlineIcon>
                        </Grid>
                    }
                    
                </Grid>
            </WrapperContainer>
        </div>
    );
}