// Component
import Grid from '@mui/material/Grid';

// Icon
import PersonIcon from '@mui/icons-material/Person';

// CSS
import navbarStyle from "assets/css/Navbar.module.css";

export default function Username({ reduxAuth }) {

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <PersonIcon className={navbarStyle.usernameIconSize}></PersonIcon>
                {/* <img className={navbarStyle.usernameImgSize} src="https://tony16888.com/images/user/profile-all.webp" alt="หวยออนไลน์"/> */}
            </Grid>
            <Grid item>
                <div className={navbarStyle.usernameText}>
                    {
                        reduxAuth !== null && (
                            reduxAuth.username
                        )
                    }
                </div>
            </Grid>
        </Grid>
    );
}