import { SET_TIME_CHECK, CLEAR_TIME_CHECK } from "@core/redux/actions/lottory/timecheck";

const initialState = {
    result: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_TIME_CHECK:
            return { ...state, result: action.payload.items };
        case CLEAR_TIME_CHECK:
            return { ...state, result: action.payload.items };
        default:
            return state;
    }
}