import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import CardContent from "views/components/CardContent";
import moment from "moment/moment";

import { loadYikiShootReward } from "@core/redux/actions/yiki/index";
// import useEffectOnce from "@utility/hooks/useEffectOnce";
// import LoadSpinner from "views/components/Loadding";

import styles from "assets/css/Style.module.css";
import orderListStyle from "views/pages/order-list/assets/OrderList.module.css";
import lottoryStyle from "views/pages/lottory/assets/Lottory.module.css";
// import { apiServices } from "@core/services/appService";

export default function YikiReward({ lottoData, roundData }) {
    const processing = useSelector((state) => state.reduxHome.processing);
    const reduxYiki = useSelector((state) => state.reduxYiki.result);
    // const [yikiRewardList, setYikiRewardList] = useState([]);
    const reduxAuthData = useSelector((state) => state.reduxAuth.result);
    const reduxLottoryData = useSelector((state) => state.reduxLottory.result);
    const reduxAuth = { ...reduxAuthData, ...reduxLottoryData };
    const [ltrData, setLtrData] = useState(null);
    // const [lottoStatus, setLottoStatus] = useState();
    const [roundId, setRoundId] = useState(0);
    // const [sendNumber, setSendNumber] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (lottoData !== null) {
            setLtrData(lottoData);
        }
    }, [processing, reduxAuth]);

    useEffect(() => {
        if (ltrData !== null) {
            setRoundId(ltrData.result_id);
            if (roundId !== null && roundId !== 0) {
                dispatch(loadYikiShootReward(roundId, reduxAuth?.token));
            }
        }
    }, [ltrData, roundId, dispatch]);

    // useEffect(() => {
    //     if (ltrData !== null) {
    //         setLottoStatus(ltrData.round_status);
    //     }
    //     // console.log(lottoStatus)
    // }, [ltrData]);

    return (
        <>
            { roundId === 0 ? ( // 5
                <>
                    <CardContent>
                        <div style={{ textAlign: "center" }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <div className="loadderAlign">
                                    <div className="loader"></div>
                                </div>
                            </div>

                            <div className={`${lottoryStyle.textLoadding} center`}>
                                รอตรวจสอบผล
                            </div>
                        </div>
                    </CardContent>
                </>
            ) : (
                <>
                    {reduxYiki !== null && reduxYiki.code === 1 ? (
                        <>
                            <CardContent boxType="radius">
                                <div
                                    className={
                                        lottoryStyle.shootRewardTitle
                                    }
                                >
                                    ปิดรับทายผลตัวเลขออกรางวัล
                                </div>

                                <div className={lottoryStyle.shootRewardHead}>
                                    ผลรางวัล
                                </div>

                                {reduxYiki?.result?.result?.reward ? (
                                    <div
                                        className={lottoryStyle.shootRewardData}
                                    >
                                        {reduxYiki?.result?.result?.reward}
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "5px",
                                        }}
                                    >
                                        <span
                                            className={
                                                lottoryStyle.tableDataHead
                                            }
                                            style={{ fontSize: "16px" }}
                                        >
                                            ไม่มีข้อมูล
                                        </span>
                                    </div>
                                )}
                            </CardContent>
                            <br />
                            <CardContent boxType="radius">
                                <Grid container direction="column" spacing={2}>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            <div
                                                className={
                                                    lottoryStyle.shootRewardHead
                                                }
                                            >
                                                สามตัวบน
                                            </div>
                                            {reduxYiki?.result?.result?.font_three ? (
                                                <div
                                                    className={
                                                        lottoryStyle.shootRewardData
                                                    }
                                                >
                                                    {
                                                        reduxYiki?.result?.result?.font_three
                                                    }
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "5px",
                                                    }}
                                                >
                                                    <span
                                                        className={
                                                            lottoryStyle.tableDataHead
                                                        }
                                                        style={{
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        ไม่มีข้อมูล
                                                    </span>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div
                                                className={
                                                    lottoryStyle.shootRewardHead
                                                }
                                            >
                                                สองตัวล่าง
                                            </div>
                                            {reduxYiki?.result?.result?.rear_two ? (
                                                <div
                                                    className={
                                                        lottoryStyle.shootRewardData
                                                    }
                                                >
                                                    {
                                                        reduxYiki?.result?.result?.rear_two
                                                    }
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "5px",
                                                    }}
                                                >
                                                    <span
                                                        className={
                                                            lottoryStyle.tableDataHead
                                                        }
                                                        style={{
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        ไม่มีข้อมูล
                                                    </span>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <div
                                                className={
                                                    lottoryStyle.shootRewardHead
                                                }
                                            >
                                                ผลรวมยิงเลข
                                            </div>
                                            {reduxYiki?.result?.result?.reward_all ? (
                                                <div
                                                    className={
                                                        lottoryStyle.shootRewardData
                                                    }
                                                >
                                                    {
                                                        reduxYiki?.result?.result?.reward_all
                                                    }
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "5px",
                                                    }}
                                                >
                                                    <span
                                                        className={
                                                            lottoryStyle.tableDataHead
                                                        }
                                                        style={{
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        ไม่มีข้อมูล
                                                    </span>
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <div
                                                className={
                                                    lottoryStyle.shootRewardHead
                                                }
                                            >
                                                เลขแถวที่ 16
                                            </div>
                                            {reduxYiki?.result?.result
                                                ?.reward_sixteen ? (
                                                <div
                                                    className={
                                                        lottoryStyle.shootRewardData
                                                    }
                                                >
                                                    {
                                                        reduxYiki?.result?.result?.reward_sixteen
                                                    }
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "5px",
                                                    }}
                                                >
                                                    <span
                                                        className={
                                                            lottoryStyle.tableDataHead
                                                        }
                                                        style={{
                                                            fontSize: "16px",
                                                        }}
                                                    >
                                                        ไม่มีข้อมูล
                                                    </span>
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <br />
                            <CardContent boxType="radius">
                                <Grid
                                    container
                                    direction="column"
                                    spacing={2}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item xs={12}>
                                        <div
                                            className={
                                                lottoryStyle.shootRewardTitle
                                            }
                                        >
                                            รางวัลโบนัสผลการยิงเลข
                                        </div>
                                    </Grid>
                                    {reduxYiki !== null ? (
                                        reduxYiki.result.customer_win !== null && reduxYiki.result.customer_win.length > 0 ? (
                                            reduxYiki.result.customer_win.map(
                                                (item, index) => (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        key={index}
                                                    >
                                                        <div
                                                            className={
                                                                lottoryStyle.shootRewardHead
                                                            }
                                                        >
                                                            {item.name}
                                                        </div>
                                                        <div
                                                            className={
                                                                lottoryStyle.shootRewardUser
                                                            }
                                                        >
                                                            {
                                                                item.customer_username
                                                            }
                                                        </div>
                                                    </Grid>
                                                )
                                            )
                                        ) : (
                                            <Grid item xs={12}>
                                                ไม่มีข้อมูล
                                            </Grid>
                                        )
                                    ) : (
                                        <Grid item xs={12}>
                                            ไม่มีข้อมูล
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>

                            <br />

                            {/* <CardContent boxType="radius"> */}
                                <div
                                    className={[
                                        styles.boxContentRadius,
                                        lottoryStyle.boxCondition,
                                    ].join(" ")}
                                    style={{ padding: "0px" }}
                                >
                                    <div
                                        className={[
                                            lottoryStyle.shootListTitle,
                                            "textBoldMedium",
                                        ].join(" ")}
                                    >
                                        รายชื่อผู้ทายเลข
                                    </div>
                                    <table className={orderListStyle.tableSize}>
                                        <tbody>
                                            {reduxYiki.result.send_number !==
                                                undefined &&
                                            reduxYiki.result.send_number !== null ? (
                                                reduxYiki.result.send_number.map(
                                                    (item, index) => (
                                                        <tr
                                                            className={[
                                                                orderListStyle.tableListPopup,
                                                                lottoryStyle.tableListPopup,
                                                                lottoryStyle.tableBorderBottom,
                                                                lottoryStyle.tableFont,
                                                            ].join(" ")}
                                                            key={index}
                                                        >
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <span
                                                                    className={
                                                                        index ===
                                                                            0 ||
                                                                        index ===
                                                                            15 ||
                                                                        index ===
                                                                            99
                                                                            ? lottoryStyle.shootListFirst
                                                                            : lottoryStyle.shootListOrder
                                                                    }
                                                                >
                                                                    ลำดับที่{" "}
                                                                    {index + 1}
                                                                </span>
                                                                <br />
                                                                {item.number}
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <span
                                                                    className={
                                                                        lottoryStyle.tableDataHead
                                                                    }
                                                                >
                                                                    ชื่อผู้ใช้
                                                                </span>{" "}
                                                                <br />
                                                                {
                                                                    item.customer_username
                                                                }
                                                            </td>
                                                            <td
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <span
                                                                    className={
                                                                        lottoryStyle.tableDataHead
                                                                    }
                                                                >
                                                                    วันที่/เวลา
                                                                </span>
                                                                <br />
                                                                {moment(
                                                                    item.time_create
                                                                ).format(
                                                                    "yyyy-MM-DD HH:mm:ss"
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            ) : (
                                                <tr
                                                    className={[
                                                        orderListStyle.tableListPopup,
                                                        lottoryStyle.tableListPopup,
                                                    ].join(" ")}
                                                >
                                                    <td
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <span
                                                            className={
                                                                lottoryStyle.tableDataHead
                                                            }
                                                        >
                                                            ไม่มีข้อมูล
                                                        </span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <br />
                                </div>
                            {/* </CardContent> */}
                        </>
                    ) : reduxYiki !== null && reduxYiki.code === 2 ? (
                    <>
                        <CardContent>
                            <div style={{ textAlign: "center" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div className="loadderAlign">
                                        <div className="loader"></div>
                                    </div>
                                </div>

                                <div className="textLoadding center">
                                    รอตรวจสอบผล
                                </div>
                            </div>
                        </CardContent>
                    </>
                    ) : reduxYiki !== null && reduxYiki.code === 3 ? (
                        <>
                            <CardContent>
                                <div
                                    style={{ textAlign: "center" }}
                                    className={lottoryStyle.tableDataHead}
                                >
                                    ยกเลิกการเดิมพัน
                                </div>
                            </CardContent>
                        </>
                    ) : reduxYiki !== null && reduxYiki.code === 0 ? (
                        <>
                            <CardContent>
                                <div
                                    style={{ textAlign: "center" }}
                                    className={lottoryStyle.tableDataHead}
                                >
                                    ไม่พบข้อมูลรอบหวย
                                </div>
                            </CardContent>
                        </>
                    ) : (
                        <>
                            <CardContent>
                                <div
                                    style={{ textAlign: "center" }}
                                    className={lottoryStyle.tableDataHead}
                                >
                                    เกิดข้อผิดพลาด
                                </div>
                            </CardContent>
                        </>
                    )}
                </>
            )}
        </>
    );
}
