import React, { useEffect, useState } from 'react'
import moment from 'moment'

// @Utility
import timeZone from "@utility/app/timeZone";

export default function TimeCount({ dataJson }) {
    const [timeCount, setTimeCount] = useState(0);
    const [statusOpen, setStatusOpen] = useState(null);
    const d = new Date();
    let year = d.getFullYear();

    const secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        setTimeCount(`${hours} : ${minutes} : ${seconds}`);
    }

    const checkYear = (date) => {
        if(timeZone(date, "year") < year) {
            return false;
        }
        else {
            return true;
        }
    };

    useEffect(() => {
        if(dataJson !== null) {
            if(checkYear(dataJson.bet_start)) {
                let datetimeNow = timeZone(moment(), "timestamp");
                let datetimeStart = timeZone(dataJson.bet_start, "timestamp");
                let datetimeEnd = timeZone(dataJson.bet_end, "timestamp");

                if(datetimeNow >= datetimeStart) {
                    if(datetimeNow <= datetimeEnd) {
                        setStatusOpen(true);
                    }
                    else {
                        setStatusOpen(null);
                    }
                }
                else {
                    setStatusOpen(false);
                }
            }
            else {
                setStatusOpen(null);
            }
        }
    }, [dataJson]);

    useEffect(() => {
        if(dataJson !== null) {
            if(timeCount >= 0) {
                let dateEnd = timeZone(statusOpen ? dataJson?.bet_end : dataJson?.bet_start, "noFormat");
                let dateCurrent = timeZone(moment(), "noFormat");
                let duration = moment.duration(dateEnd.diff(dateCurrent));
                let seconds = duration.asSeconds();
    
                const checkTime = setInterval(() => {
                    --seconds;
                    if(seconds <= 0) {
                        clearInterval(checkTime);
                        setTimeCount(0);
                    }
                    else {
                        secondsToTime(seconds);
                    }
                }, 1000);

                // setCheckTimeLeft(setInterval(() => {
                //     --seconds;
                //     if(seconds <=0) {
                //         clearInterval(checkTimeLeft);
                //         setTimeCount(0);
                //     } else {
                //         secondsToTime(seconds);
                //     }
                // }, 1000))
    
                return () => {
                    clearInterval(seconds);
                }
            }
        }
    }, [dataJson, statusOpen])
    return (
        <>
            {
                timeCount <= 0 ? "00:00:00" : timeCount
            }
        </>
    )
}
