import { useEffect, useState } from 'react';

// @Utility
import lotteryFormat from "@utility/app/lotteryFormat";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setBasketData } from "@core/redux/actions/basket/index";

// Component
import Grid from '@mui/material/Grid';

// Icon
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

// CSS
import buttonStyle from "assets/css/Button.module.css";
import lottoryStyle from "views/pages/lottory/assets/Lottory.module.css";

export default function TypeLottory({ typeData, selectType, setSelectType, dataCode, statusOpen, setDataCode, dataCodeOther, setDataCodeOther, reandonMakeId, checkNumberRate, alertTxt, scrollRef }) {
    const [dataJson, setDataJson] = useState(null);
    const [selectTypeChange, setSelectTypeChange] = useState([]);
    const [selectTypeOther, setSelectTypeOther] = useState([]);
    const [statusAddon, setStatusAddon] = useState(null);
    const [statusChange, setStatusChange] = useState(1);
    const lottoryData = useSelector(state => state.reduxBasket.lottory);
    const dispatch = useDispatch();

    const setLenRowType = (rowCount, type, addon) => { // เซ็ทจำนวนช่องที่กรอกตัวเลข
        if(rowCount !== null) {
            if(type === "sub") {
                setSelectType(rowCount);
            }
            else {
                if(addon === null) {
                    setSelectType(1);
                }
                else {
                    if(selectType > rowCount) {
                        setSelectType(selectType);
                    }
                    else {
                        setSelectType(rowCount);
                    }
                }
            }
        }
        else {
            setSelectType(null);
        }
    };

    const selectTypeLottory = ({ length: rowCount, code, addon, name, min, max, max_number, price }) => {
        let dataArr = [];

        if(checkTypeCode(code) === undefined) {
            let positionTop = (scrollRef.current.offsetTop);
            
            window.scrollTo({
                top: positionTop,
                left: 0,
                behavior: "smooth"
            });

            dataArr = dataCode;
            let checkDataArrCode = dataCodeOther.filter(item => (item.code === "3HT99" || item.code === "2HT99"));  // ดึงค่าเลขกลับ
            if(checkDataArrCode.length > 0) {  // ถ้ามีค่าเลขกลับ ให้เลือกเฉพาะตรามจำนวนเช่น กลับ 2ตัว ให้เลือกได้แค่หวย 2 ตัว ถ้าเลือกประเภทอื่นที่ 3 ตัว ให้รีเซ็ทเลขกลับออก
                let dataArrCode = checkDataArrCode[0];
                if(rowCount === dataArrCode.length) {
                    dataArr = [...dataCode, {code, length: rowCount, addon, name, min, max, max_number, price}];
                    setDataCode(dataArr);
                }
                else { // รีเซ็ทเลขกลับ
                    dataArr = dataCode;
                    let dataFilter = dataArr.filter(item => (item.length === rowCount));
                    let dataOtherFilterPop = dataCodeOther.filter(item => item.code !== dataArrCode.code);
                    dataFilter = [...dataFilter, {code, length: rowCount, addon, name, min, max, max_number, price}];
                    setDataCodeOther(dataOtherFilterPop);
                    setDataCode(dataFilter);

                    if(rowCount === 2) {
                        setStatusAddon(addon);
                    }
                }

                setLenRowType(rowCount, "add", addon);
            }
            else {
                dataArr = [...dataCode, {code, length: rowCount, addon, name, min, max, max_number, price}];
                let checkAddon = dataArr.filter(item => item.length === 3);
                let check1HT = dataArr.filter(item => (item.code === "1HT02" || item.code === "1HT01"));

                if(checkAddon.length > 0) {
                    setDataCodeOther([]);
                    setStatusAddon(0);
                }
                else {
                    if(check1HT.length) {
                        setDataCodeOther([]);
                        setStatusAddon(0);
                    }
                    else {
                        if(rowCount === 2) {
                            setStatusAddon(addon);
                        }
                    }
                }

                setDataCode(dataArr);
                setLenRowType(rowCount, "add", addon);
            }

            if(code === "3HT02") {
                resetTypeLottoChange();
                setStatusChange(0);
            }

            dataArr = [];
        }
        else {
            dataArr = dataCode;
            dataArr = dataArr.filter(item => item.code !== code);
            setDataCode(dataArr);

            if(code === "3HT02") {
                setStatusChange(1);
            }

            if(dataArr.length === 0) {
                setLenRowType(null);
                setStatusAddon(null);
                setDataCode([]);
                setDataCodeOther([]);
            }
            else {
                resetAddonShow(dataArr, addon);
            }

            dataArr = [];
        }
    };

    const resetTypeLottoChange = () => { // รีเซ็ท เลขกลับสำหรับสามตัวโต๊ด
        let dataOtherFilterPop = dataCodeOther.filter(item => (item.code !== "3HT99" && item.code !== "2HT99"));
        setDataCodeOther(dataOtherFilterPop);
    };
    
    const selectTypeLottoryOther = ({ length: rowCount, code, addon, name, min, max, max_number, price }) => {
        if(statusOpen) {
            let dataArr = [];
            if(checkTypeCodeOther(code) === undefined) {
                if(code === "0HT01" || code === "0HT02" || code === "0HT03" || code === "0HT04" || code === "0HT05") {
                    let numberLottory = lotteryFormat(0, code);
                    let dataNumber = [];
                    dataCode.forEach(element => {
                        numberLottory.forEach((number) => {
                            let lottoryRate = checkNumberRate(element, number);
                            if(lottoryRate !== false) {
                                dataNumber = [...dataNumber, {
                                    ...element,
                                    number: number, 
                                    bet: (element.min > 0 ? 1 : 0),
                                    key: reandonMakeId(3),
                                    price: lottoryRate.price,
                                    is_cancel: false,
                                    is_max: false,
                                    is_max_number: false,
                                    is_min: false,
                                    is_price_rate: false
                                }];
                            }
                            else {
                                dataNumber = [...dataNumber, {
                                    ...element,
                                    number: number, 
                                    bet: (element.min > 0 ? 1 : 0),
                                    key: reandonMakeId(3),
                                    is_cancel: false,
                                    is_max: false,
                                    is_max_number: false,
                                    is_min: false,
                                    is_price_rate: false
                                }];
                            }
                        });
                    });
    
                    alertTxt("แจ้งเตือน", true, "success", `เพิ่มตัวเลข ${dataNumber.length} รายการ`);
                    dispatch(setBasketData([...lottoryData, ...dataNumber]));
                }
                else if(code === "3HT99" || code === "2HT99") { // เซ็ทค่าเลขกลับ
                    dataArr = dataCode;
                    if(dataArr.length <= 0) {
                        let dataArrCodeFilter = dataJson.filter((item) => ((item.length === rowCount && item.code !== "3HT02")));
    
                        if(dataArrCodeFilter.length > 0) {
                            setDataCode(dataArrCodeFilter);
                        }
                    }
                    else {
                        let dataFilter = dataArr.filter(item => (item.length === rowCount));
                        setDataCode(dataFilter);
    
                        if(dataFilter.length <= 0) {
                            let dataArrCodeFilter = dataJson.filter((item) => ((item.length === rowCount && item.code !== "3HT02")));
    
                            if(dataArrCodeFilter.length > 0) {
                                setDataCode(dataArrCodeFilter);
                            }
                        }
                    }
    
                    setStatusAddon(0);
                    setDataCodeOther([{code, length: rowCount, addon, name, min, max, max_number, price}]);
                    setLenRowType(rowCount, "sub", addon);
                    dataArr = [];
                }
                else {
                    setDataCodeOther([...dataCodeOther, {code, length: rowCount, addon, name, min, max, max_number, price}]);
                    setLenRowType(1, "sub", 1);
                }
            }
            else {
                let dataOtherFilterPop = dataCodeOther.filter(item => item.code !== code);
                dataArr = dataCode;
                setDataCodeOther(dataOtherFilterPop);
    
                if(dataArr.length <= 0 && dataOtherFilterPop.length <= 0) {
                    setLenRowType(null);
                }
                else {
                    if(dataArr.length > 0) {
                        if(dataOtherFilterPop.length > 0) {
                            resetAddonShow(dataArr, addon);
                        }
                        else {
                            let lenTwo = dataArr.filter(item => item.length === 2);
                            let lenAddon = dataArr.filter(item => item.addon === 1);
                            if(lenTwo.length > 0) {
                                if(lenAddon.length > 0) {
                                    setStatusAddon(1);
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    const resetAddonShow = (dataArr, addon) => {
        let lenType =  Math.max(...dataArr.map(element => element.length));
        let lenThree = dataArr.filter(item => item.length === 3);
        let lenTwo = dataArr.filter(item => item.length === 2);
        let lenOne = dataArr.filter(item => item.length === 1);
        let lenAddon = dataArr.filter(item => item.addon === 1);
        let lenAddonNull = dataArr.filter(item => item.addon === null);

        if(lenAddonNull.length > 0) {
            setLenRowType(1, "sub", addon);
        }
        else {
            setLenRowType(lenType, "sub", addon);
        }

        if(lenTwo.length <= 0 || (lenThree.length > 0 || lenOne.length > 0)) {
            setStatusAddon(0);
        }
        else {
            let dataOtherFilterChange = dataCodeOther.filter(item => (item.code === "3HT99" || item.code === "2HT99"));
            if(dataOtherFilterChange.length > 0) {
                setStatusAddon(0);
            }
            else {
                if(lenAddon.length > 0) {
                    setStatusAddon(1);
                }
            }
        }
    };

    const checkTypeCode = (code) => {
        let dataCheck = dataCode.find((item) => {
            return item.code === code;
        });

        return dataCheck;
    };

    const checkTypeCodeOther = (code) => {
        let dataCheck = dataCodeOther.find((item) => {
            return item.code === code;
        });

        return dataCheck;
    };

    useEffect(() => {
        if(typeData !== null) {
            setDataJson(typeData);

            let dataTypeChange = [
                {
                    code: "3HT99",
                    length: 3,
                    name: "กลับ 3 ตัว",
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                },
                {
                    code: "2HT99",
                    length: 2,
                    name: "กลับ 2 ตัว",
                    addon: null,
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                }
            ];

            let dataTypeOther = [
                {
                    code: "1HT19",
                    length: 1,
                    name: "19ประตู",
                    addon: null,
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                },
                {
                    code: "0HT01",
                    length: null,
                    name: "เลขเบิ้ล",
                    addon: null,
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                },
                {
                    code: "1HT20",
                    length: 1,
                    name: "รูดหน้า",
                    addon: null,
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                },
                {
                    code: "1HT21",
                    length: 1,
                    name: "รูดหลัง",
                    addon: null,
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                },
                {
                    code: "0HT02",
                    length: null,
                    name: "สองตัวต่ำ",
                    addon: null,
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                },
                {
                    code: "0HT03",
                    length: null,
                    name: "สองตัวสูง",
                    addon: null,
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                },
                {
                    code: "0HT04",
                    length: null,
                    name: "สองตัวคี่",
                    addon: null,
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                },
                {
                    code: "0HT05",
                    length: null,
                    name: "สองตัวคู่",
                    addon: null,
                    addon: null,
                    min: null,
                    max: null,
                    max_number: null,
                    price: null,
                    other: true
                }
            ];

            setSelectTypeChange(dataTypeChange);
            setSelectTypeOther(dataTypeOther);
        }
    }, [typeData]);

    return (
        <>
            <Grid container spacing={2}>
                {
                    dataJson !== null && (
                        dataJson.map((item, index) => (
                            <Grid item xs={6} md={4} key={index}>
                                <div className={checkTypeCode(item.code) === undefined ? [buttonStyle.btnOutLineColor, buttonStyle.outLineDanger, "center"].join(" ") : [buttonStyle.btnOutLineColor, buttonStyle.outLineDanger, buttonStyle.outLineDangerActive, "center"].join(" ")} onClick={() => selectTypeLottory(item)}>
                                    { item.name } <span className={["textBoldHight", checkTypeCode(item.code) === undefined ? "textCLBrand" : "" ].join(" ")}>({ item.price })</span>
                                </div>
                            </Grid>
                        ))
                    )
                }
            </Grid>

            {
                statusChange === 1 && (
                    <div className={lottoryStyle.boxTypeBorder}>
                        <Grid container spacing={2} alignItems="center">
                            {
                                selectTypeChange !== null && (
                                    selectTypeChange.map((item, index) => (
                                        <Grid item xs={6} md={4} key={index}>
                                            <div className={checkTypeCodeOther(item.code) === undefined ? [buttonStyle.btnOutLineColor, buttonStyle.outLineDanger, "center"].join(" ") : [buttonStyle.btnOutLineColor, buttonStyle.outLineDanger, buttonStyle.outLineDangerActive, "center"].join(" ")} onClick={() => selectTypeLottoryOther(item)}>
                                                { item.name } 
                                                <span className={[lottoryStyle.typeIconAlign, checkTypeCodeOther(item.code) === undefined ? "textCLBrand" : ""].join(" ")}>
                                                    <SwapHorizIcon className={lottoryStyle.typeIconSize}></SwapHorizIcon>
                                                </span>
                                            </div>
                                        </Grid>
                                    ))
                                )
                            }
                        </Grid>
                    </div>
                )
            }

            {
                statusAddon === 1 && (
                    <Grid container spacing={2}>
                        {
                            selectTypeOther !== null && (
                                selectTypeOther.map((item, index) => (
                                    <Grid item xs={3} key={index}>
                                        <div className={checkTypeCodeOther(item.code) === undefined ? [buttonStyle.btn, buttonStyle.btnTypeLotOther, "center"].join(" ") : [buttonStyle.btn, buttonStyle.btnTypeLotOtherActive, "center"].join(" ")} onClick={() => selectTypeLottoryOther(item)}>
                                            { item.name }
                                        </div>
                                    </Grid>
                                ))
                            )
                        }
                    </Grid>
                )
            }
        </>
    );
}