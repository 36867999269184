import { 
    SET_DATA_LOTTORY
} from "@core/redux/actions/lottory/index";

const initialState = {
    result: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_LOTTORY:
            return { ...state, result: action.payload.items };
        default:
            return state;
    }
}