import { React, useState } from 'react';
import moment from 'moment/moment';
import { Table, TableBody, TableCell, TableContainer, TableRow, Grid } from '@mui/material';

import ButtonStyle from "assets/css/Button.module.css";

export default function SingleType({dataList, next, setNext, cardPerTime}) {
    const [loading, setLoading] = useState(false)
    
    const handleMoreReward = () => {
        setLoading(true);
        const checkTime = setInterval(() => {
            setNext(next + cardPerTime);
            setLoading(false);
            clearInterval(checkTime);
        }, 600);
    }
    
    const iconCode = (val) => {
        if (val === "HSTL" || val === "HT00") {
            return "images/icon/thaigov.webp"       // ไทย
        } else if (val === "HSGM") {
            return "images/icon/german.webp"        // เยอร์มัน
        } else if (val === "HSRS") {
            return "images/icon/russia.webp"        // รัสเซีย
        } else if (val === "HSEY") {
            return "images/icon/egypt.webp"         // อียิปต์
        } else if (val === "HSSP") {
            return "images/icon/singapore.webp"     // สิงคโปร์
        } else if (val === "HSDJ") {
            return "images/icon/downjones.webp"     // เมกา - ดาวน์โจนส์
        } else if (val === "HSTW") {
            return "images/icon/taiwan.webp"        // ไต้หวัน
        } else if (val === "HSEN") {
            return "images/icon/england.webp"       // อังกฤษ
        } else if (val === "HSID") {
            return "images/icon/india.webp"         // อินเดีย
        } else if (val === "HSCN") {
            return "images/icon/chinacircle.webp"   // จีน
        } else if (val === "HSHK") {
            return "images/icon/hongkong.webp"      // ฮ่องกง - ฮั่งเส็ง
        } else if (val === "HSJP") {
            return "images/icon/japan.webp"         // ญี่ปุ่น - นิเคอิ
        } else if (val === "HSKR") {
            return "images/icon/korea.webp"         // เกาหลี
        } else if (val === "HBBA") {
            return "images/icon/baac.webp"          // ธกส
        } else if (val === "HBGS") {
            return "images/icon/gsb.webp"           // ออมสิน
        } else if (val === "HHN0") {
            return "images/icon/hanoycircle.webp"   // ฮานอย - ฮานอย พิเศษ - ฮานอย VIP
        } else if (val === "HML0") {
            return "images/icon/malaycircle.webp"   // มาเลย์
        } else if (val === "HYK0") {
            return "images/icon/yikicircle.webp"   // ยี่กี
        } else if (val === "HL00") {
            return "images/icon/laoscircle.webp"   // ลาว
        }
    }

    const dataSkipNull = dataList.filter((item) => {return item.lottery_code !== ''})

  return (
    <>
        {
                dataList !== null && dataList !== undefined && dataList !== [] && dataList.length > 0 ?
                <>
                    {
                        dataSkipNull?.slice(0, next)?.map((item, index) => (
                            <div style={{ marginTop: "20px" }} key={index}>
                                <div style={{ borderRadius: "10px 10px 0px 0px", background: "var(--bg-grey)" }}>
                                    <Grid
                                        container 
                                        direction="row" 
                                        justifyContent="space-between" 
                                        alignItems="center" 
                                        style={{ 
                                            color: "var(--bg-white)",
                                            padding: "15px 20px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        <Grid item container xs={7} sm={9} spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item>
                                                <img
                                                    src={iconCode(item.lottery_code.split("").splice(0,4).join(""))}
                                                    alt={item.lottery_name}
                                                    style={{ width: "23px", verticalAlign: "middle" }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                { item.lottery_name }
                                            </Grid>
                                        </Grid>
                                        <Grid item  xs={5} sm={3} style={{ background: "var(--bg-white)", fontWeight: "bold", fontSize: "14px", borderRadius: "15px", color: "var(--bg-black)", padding: "3px 15px", textAlign: "center" }}>
                                            { moment(item.result_time).format("yyyy-MM-DD") }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ background: "var(--bg-card)", borderRadius: "0px 0px 10px 10px" }}>
                                    <TableContainer>
                                        <Table sx={{ minWidth: "100%", borderRadius: "0px 0px 10px 10px" }}>
                                            <TableBody>
                                                
                                                {
                                                    item?.lottory_main !== null && item?.lottory_main !== undefined ?
                                                    item?.lottory_main.map((item2, index2) => (
                                                        <TableRow key={index2}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item2.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ minWidth: "100px", fontWeight: "bold", fontSize: "15px" }}>
                                                                { item2.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                {
                                                    item?.lottory_sub !== null && item?.lottory_sub !== undefined ?
                                                    item?.lottory_sub.map((item3, index3) => (
                                                        <TableRow key={index3}>
                                                            <TableCell variant="head" align="center" sx={{ borderRight: "1px solid var(--bg-line)", fontWeight: "bold", color: "var(--bg-brand)", fontSize: "16px" }}>
                                                                { item3.name }
                                                            </TableCell>
                                                            <TableCell align="center" sx={{ fontWeight: "bold", fontSize: "15px" }}>
                                                                { item3.number }
                                                            </TableCell>
                                                        </TableRow>
                    
                                                    ))
                                                    :
                                                    <></>
                                                }
                                                            
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        ))
                    }

                    {
                        next < dataSkipNull?.length && (
                            <div className={ButtonStyle.btnLoadMore} onClick={handleMoreReward}>
                                { loading ? "กำลังโหลด..." : "แสดงข้อมูลเพิ่มเติม" }
                            </div>
                        )
                    }
                </>
                :
                <></>
            }
    </>
  )
}
