import Container from '@mui/material/Container';

export default function WrapperContainer(props) {

    return (
        <Container className="containerWidth" maxWidth="md">
            {
                props.children
            }
        </Container>
    );
}