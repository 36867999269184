import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// @Utility
import currencyFormat from "@utility/numbers/currencyFormat";
import setBodyScroll from "@utility/app/setBodyScroll";

// Component
import ModalFull from 'views/components/ModalFull';
import Grid from '@mui/material/Grid';
import WrapperContainer from "views/components/WrapperContainer";

// CSS
import navbarStyle from "assets/css/Navbar.module.css";
import modalStyles from "assets/css/Modal.module.css";
import orderListStyle from "views/pages/order-list/assets/OrderList.module.css";

// Icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ResultModal({ dataList, resultModal, setResultModal, nameTitle }) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [dataArr, setDataArr] = useState(null);
    const onShow = (val) => {
        setBodyScroll(val);

        setResultModal({ ...resultModal, show: val });
    };

    const renderStatus = ({ status, winloss }) => {

        if (status === 5) {
            return (
                winloss === true ?
                    <span className="label labelFontSize labelSuccess">
                        ชนะ
                    </span>
                    :
                    <span className="label labelFontSize labelDanger">
                        แพ้
                    </span>
            );
        }
        else if (status === 6) {
            return (
                <span className="label labelFontSize labelDanger">
                    ยกเลิก
                </span>
            );
        }
        else if (status === 7) {
            return (
                <span className="label labelFontSize labelInfo">
                    คืนโพย
                </span>
            );
        }
        else {
            return (
                <span className="label labelFontSize labelWarning">
                    รอผล
                </span>
            );
        }
    };

    const renderTable = (dataArr) => {
        return (
            <table className={orderListStyle.tableSize}>
                <thead>
                    <tr>
                        <th className={[orderListStyle.theadSizePopup, orderListStyle.listTdPaddingPopup, dataArr.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "left", width: "15%" }}>ประเภท</th>
                        <th className={[orderListStyle.theadSizePopup, dataArr.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "left", width: "15%" }}>เลขที่เเทง</th>
                        <th className={[orderListStyle.theadSizePopup, dataArr.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "right", width: "20%" }}>ราคาเเทง</th>
                        <th className={[orderListStyle.theadSizePopup, dataArr.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "right", width: "20%" }}>ราคาจ่าย</th>
                        <th className={[orderListStyle.theadSizePopup, dataArr.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "center", width: "15%" }}>เลขที่ออก</th>
                        <th className={[orderListStyle.theadSizePopup, dataArr.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "right", width: "25%" }}>ผลได้เสีย</th>
                        <th className={[orderListStyle.theadSizePopup, dataArr.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder].join(" ")} style={{ textAlign: "center", width: "20%" }}>สถานะ</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataArr.length > 0 ?
                            dataArr.map((item, index) => (
                                <tr key={item.id} className={[orderListStyle.tableListPopup, (index % 2 === 0) ? orderListStyle.listGrey : orderListStyle.listDefault].join(" ")}>
                                    <td className={orderListStyle.listTdPaddingPopup} style={{ textAlign: "left" }}>
                                        {
                                            item.lottery_type
                                        }
                                    </td>
                                    <td className="textBoldHight" style={{ textAlign: "left" }}>
                                        {
                                            item.number
                                        }
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {
                                            currencyFormat(item.bet_amount, 2)
                                        }
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {
                                            currencyFormat(item.price_rate, 2)
                                        }
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {
                                            item.number_result
                                        }
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {
                                            currencyFormat(item.win_amount, 2)
                                        }
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {
                                            renderStatus(item)
                                        }
                                    </td>
                                </tr>
                            ))
                            :
                            <tr>
                                <td colSpan={12}>
                                    <div className={["center", orderListStyle.dataNull].join(" ")}>
                                        ไม่มีข้อมูล
                                    </div>
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
        );
    };

    const renderList = (dataArr) => {
        return (
            dataArr.map((item, index) => (
                <div key={index} className={orderListStyle.listGrid}>
                    <Grid container alignItems="center">
                        <Grid item xs={1}>
                            <div className={orderListStyle.listNumber}>
                                #{++index}
                            </div>
                        </Grid>
                        <Grid item xs={8} className="textBoldHight">
                            { item.lottery_type } &nbsp;&nbsp;<span className='textCLRegister'>{ item.number }</span>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="right">
                                {
                                    renderStatus(item)
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <div className={orderListStyle.listTextResult}>
                        <Grid container alignItems="center">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    ราคาที่เเทง :
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    <span className="textCLDefult textBoldHight">{currencyFormat(item.bet_amount, 2)}</span> บาท
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    เลขที่ออก :
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    <span className="textCLDefult textBoldHight">{item.number_result === "" ? "-" : item.number_result}</span>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={orderListStyle.listTextResult}>
                        <Grid container alignItems="center">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    ราคาจ่าย :
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    <span className="textCLDefult textBoldHight">{currencyFormat(item.price_rate, 2)}</span> บาท
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    ผลได้เสีย :
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    <span className="textCLDefult textBoldHight">{currencyFormat(item.win_amount, 2)}</span> บาท
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    {/* <div className={orderListStyle.listTextResult}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    เลขที่ออก : 
                                </div> 
                            </Grid>
                            <Grid item xs={8}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    <span className="textCLDefult textBoldHight">{ item.number_result === "" ? "-" : item.number_result }</span> 
                                </div> 
                            </Grid>
                        </Grid>
                    </div> */}
                    {/* <div className={orderListStyle.listTextResult}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    ผลได้เสีย : 
                                </div> 
                            </Grid>
                            <Grid item xs={8}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    <span className="textCLDefult textBoldHight">{ currencyFormat(item.win_amount, 2) }</span> บาท
                                </div> 
                            </Grid>
                        </Grid>
                    </div> */}
                    {/* <div className={orderListStyle.listTextResult}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    ราคาที่เเทง : <span className="textCLDanger textBoldHight">100.00</span> บาท
                                </div> 
                            </Grid>
                            <Grid item xs={5}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    เลขที่ออก : <span className="textCLWarning textBoldHight">100</span>
                                </div> 
                            </Grid>
                        </Grid>
                    </div> */}
                    {/* <div className={orderListStyle.listTextResult}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={6}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    ราคาที่จ่าย : <span className="textCLDanger textBoldHight">100.00</span> บาท
                                </div> 
                            </Grid>
                            <Grid item xs={5}>
                                <div className={orderListStyle.listTextResultInfo}>
                                    ผลได้เสีย : <span className="textCLSuccess textBoldHight">100.00</span>  บาท
                                </div> 
                            </Grid>
                        </Grid>
                    </div> */}
                </div>
            ))
        );
    };

    useEffect(() => {
        setDataArr([]);

        if (dataList !== null) {
            if (dataList?.data === undefined) {
                setDataArr([]);
            }
            else {
                setDataArr([]);
                setDataArr(dataList.data);
            }
        }
    }, [dataList]);

    return (
        <ModalFull show={resultModal.show} onShow={onShow} bgClass="1">
            {
                isLargeScreen ?
                    <div className={[modalStyles.conditionTitle, modalStyles.conditionTitlePaddingOther].join(" ")}>
                        {nameTitle}
                    </div>
                    :
                    <div className={[modalStyles.conditionTitle, modalStyles.conditionTitlePaddingOther].join(" ")}>
                        <Grid container alignItems="center">
                            <Grid item xs={3}>
                                <div className={navbarStyle.cursorBack} onClick={() => onShow(false)} style={{ color: "white" }}>
                                    <span className={navbarStyle.cursorBackAlign}>
                                        <ArrowBackIcon className={navbarStyle.backIcon}></ArrowBackIcon>
                                    </span>

                                    ย้อนกลับ
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="center">
                                    {nameTitle}
                                </div>
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>
                    </div>
            }
            <div className={[modalStyles.conditionBodyRadius, modalStyles.conditionBodyPadding, orderListStyle.bgFloor].join(" ")}>
                <div className={orderListStyle.tableModalAlign} style={{ overflowY: "auto" }}>
                    {
                        dataArr !== null && (
                            // isLargeScreen ?
                            // renderTable(dataArr)
                            // :
                            renderList(dataArr)
                        )
                    }
                </div>
            </div>

            <br /><br />
        </ModalFull>
    );
}