const SET_RELOAD_SETTING = 'app/SET_RELOAD_SETTING';

function reloadSetting(data) {
    return {
        type: SET_RELOAD_SETTING,
        payload: {
            items: data
        }
    }
}

export {
    SET_RELOAD_SETTING,
    reloadSetting
}