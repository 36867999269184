import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// @Utility
import timeZone from "@utility/app/timeZone";

// Component
import CardContent from "views/components/CardContent";
import InputNumber from "views/pages/lottory/component/InputNumber";
import TypeLottory from "views/pages/lottory/component/TypeLottory";
import TimeOut from "views/pages/lottory/component/TimeOut";
import Condition from "views/pages/lottory/component/Condition";
import Footer from "views/pages/lottory/component/Footer";
import Snackbar from "views/components/Snackbar";
import ShootNumber from "views/pages/lottory/component/ShootNumber";
// import ShootList from "views/pages/lottory/component/ShootList";
import YikiReward from "views/pages/lottory/component/YikiReward";
// import { checkCloseRound, clearTimeCheck } from '@core/redux/actions/lottory/timecheck';

import {
    Tabs,
    Tab,
    Typography,
    Box
} from '@mui/material'

// Icon
import ListAltIcon from '@mui/icons-material/ListAlt';
import MarginIcon from '@mui/icons-material/Margin';

// CSS
import styles from "assets/css/Style.module.css";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function ContentLottory({ reduxAuth, lottery_code }) {
    const scrollRef = useRef();
    const [lottoryData, setLottoryData] = useState(null);
    const [typeData, setTypeData] = useState([]);
    const [selectType, setSelectType] = useState(null);
    const [dataCode, setDataCode] = useState([]);
    const [dataCodeOther, setDataCodeOther] = useState([]);
    const [numberRate, setNumberRate] = useState([]);
    const [checkRound, setCheckRound] = useState(null);
    const [statusOpen, setStatusOpen] = useState(null);
    const [yikiResultId, setYikiResultId] = useState(null);
    const reduxTimecheck = useSelector((state) => state.reduxTimecheck.result);
    const [timeCheck, setTimeCheck] = useState(null);
    const [lottoStatus, setLottoStatus] = useState();
    const [roundStatus, setRoundStatus] = useState(null);
    const [snackbarData, setSnackbarData] = useState({
        title: "",
        show: false,
        status: "",
        message: ""
    }); 
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const reandonMakeId = (length) => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
        }

        return result + (new Date()).getTime();
    }

    const checkNumberRate = (item, number) => {
        if(numberRate !== null) {
            if(numberRate.length > 0) {
                let dataArr = [];
                dataArr = numberRate.filter((element) => (element.code === item.code && element.number === number));
    
                if(dataArr.length > 0) {
                    return dataArr[0];
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };

    const alertTxt = (title, show, status, message) => {
        setSnackbarData({
            title,
            show, 
            status, 
            message
        });
    };

    const checkYear = (date) => {
        if(timeZone(date, "year") < 2022) {
            return false;
        }
        else {
            return true;
        }
    };

    useEffect(() => {
        if(reduxAuth !== null) {
            setLottoryData(reduxAuth.lottery);
            setTypeData(reduxAuth.lottery_type);
            setNumberRate(reduxAuth.lottery_rate);
            setCheckRound(reduxAuth.lottery.round_id);
            setRoundStatus(reduxAuth.lottery.round_status);
        }
    }, [reduxAuth]);

    useEffect(() => {
        if(lottoryData !== null) {
            if(checkYear(lottoryData.bet_start)) {
                let datetimeNow = timeZone(moment(), "timestamp");
                let datetimeStart = timeZone(lottoryData.bet_start, "timestamp");
                let datetimeEnd = timeZone(lottoryData.bet_end, "timestamp");

                if(datetimeNow >= datetimeStart) {
                    if(datetimeNow <= datetimeEnd) {
                        setStatusOpen(true);
                    }
                    else {
                        setStatusOpen(null);
                    }
                }
                else {
                    setStatusOpen(false);
                }
            }
            else {
                setStatusOpen(null);
            }

            
        }
    }, [lottoryData]);

    useEffect(() => {
        if (lottoryData !== null) {
            setYikiResultId(lottoryData.result_id)
        }
    }, [lottoryData])

    const lottoCodeSplit = lottery_code.split("")
    const lottoCode = lottoCodeSplit.splice(0, 3).join("")
    // const yikiResultId = lottoryData.result_id

    // console.log("lottory status -> ",lottoryData.round_status)

    // console.log("yikiResultId-> ", yikiResultId)

    useEffect(() => {
        if (reduxTimecheck !== null) {
            setTimeCheck(reduxTimecheck);
        } else {
            setTimeCheck(null);
        }
        // console.log("timeCheck", timeCheck);
    }, [reduxTimecheck, timeCheck]);

    useEffect(() => {
        if (lottoryData !== null) {
            if (lottoryData.round_status === 0 || lottoryData.round_status === 1 || lottoryData.round_status === 6 || lottoryData.round_status === 7 || lottoryData.round_status === 8) {
                setLottoStatus(false);
            } else {
                setLottoStatus(true)
            }
        }
        
    }, [lottoryData, setLottoStatus]);

    // console.log(lottoStatus)
    // console.log("content time check -> ", reduxTimecheck);

    // console.log(statusOpen) เปิด - true / ปิด - null

    return (
        <>
            {
                timeCheck === null ?
                <>
                    {
                        snackbarData.show && (
                            <Snackbar {...snackbarData} setSnackbarData={setSnackbarData}></Snackbar>
                        )
                    }

                    {
                        statusOpen ?
                        <div className={styles.boxPaddingBottom}>
                            

                            {
                                lottoCode === "HYK" ?
                                    <div className={styles.boxContent}>
                                        <Tabs
                                            variant="fullWidth"
                                            value={value}
                                            onChange={handleChange}
                                            classes={{
                                                indicator: styles.indicator
                                            }}
                                        >
                                            <LinkTab label="แทงหวย" className={value === 0 ? styles.tabTextActive : styles.tabText} icon={<ListAltIcon className={styles.tabIconSize} />} {...a11yProps(0)} />
                                            <LinkTab label="ยิงเลข" className={value === 1 ? styles.tabTextActive : styles.tabText} icon={<MarginIcon className={styles.tabIconSize} />} {...a11yProps(1)} />
                                        </Tabs>
                                    </div>
                                :
                                <></>
                            }
                            

                            <div className={styles.boxMarginTop}>
                            
                                <CardContent boxType="radius">
                                    <TimeOut lottoryData={lottoryData} checkRound={checkRound} statusOpen={statusOpen} roundStatus={roundStatus}></TimeOut>
                                </CardContent>

                            </div>

                            <TabPanel value={value} index={0} className={styles.tabContent}>

                                <div ref={scrollRef}>
                                    <TypeLottory 
                                        typeData={typeData} 
                                        selectType={selectType} 
                                        setSelectType={setSelectType} 
                                        dataCode={dataCode} 
                                        setDataCode={setDataCode} 
                                        dataCodeOther={dataCodeOther} 
                                        setDataCodeOther={setDataCodeOther} 
                                        reandonMakeId={reandonMakeId} 
                                        checkNumberRate={checkNumberRate} 
                                        alertTxt={alertTxt}
                                        scrollRef={scrollRef}
                                        statusOpen={statusOpen}
                                    ></TypeLottory>

                                    {
                                        (checkRound !== 0 && checkRound !== null) && (
                                            statusOpen && (
                                                selectType !== null && (
                                                    <div className={styles.boxMarginTop}>
                                                        <InputNumber selectType={selectType} dataCode={dataCode} dataCodeOther={dataCodeOther}  reandonMakeId={reandonMakeId} numberRate={numberRate} checkNumberRate={checkNumberRate} alertTxt={alertTxt}></InputNumber>
                                                    </div>
                                                )
                                            )
                                        )
                                    }
                                </div>

                                <div className={styles.boxMarginTop}>
                                    <Condition typeData={typeData}></Condition>
                                </div>

                                <Footer CardContent={CardContent} lottery_code={lottery_code}></Footer>
                            </TabPanel>

                            {
                                lottoCode === "HYK" ?
                                <TabPanel value={value} index={1}>
                                    <div className={styles.boxMarginTop}>
                                        <ShootNumber lottoData={lottoryData} dataCode={dataCode} roundData={checkRound} alertTxt={alertTxt}></ShootNumber>
                                    </div>

                                    {/* <div className={styles.boxMarginTop}>
                                        <ShootList roundData={checkRound}></ShootList>
                                    </div> */}
                                </TabPanel>
                                : <></>
                            }
                            
                        </div>
                        :
                        <>
                        {
                            lottoCode === "HYK" && lottoStatus ?
                            <div className={styles.boxPaddingBottom}>
                                <div className={styles.boxMarginTop}>
                                    
                                    <CardContent boxType="radius">
                                        <TimeOut lottoryData={lottoryData} checkRound={checkRound} statusOpen={statusOpen} roundStatus={roundStatus}></TimeOut>
                                    </CardContent>

                                </div>

                                <div className={styles.boxMarginTop}>
                                    {/* <YikiReward roundData={yikiResultId} lottoData={lottoryData}></YikiReward> */}
                                    <YikiReward lottoData={lottoryData}></YikiReward>
                                </div>
                            </div>
                            :
                            <>
                            <div className={styles.boxMarginTop}>
                            
                                <CardContent boxType="radius">
                                    <TimeOut lottoryData={lottoryData} checkRound={checkRound} statusOpen={statusOpen} roundStatus={roundStatus}></TimeOut>
                                </CardContent>

                            </div>
                                <div className={styles.boxMarginTop} ref={scrollRef}>
                                    <TypeLottory 
                                        typeData={typeData} 
                                        selectType={selectType} 
                                        setSelectType={setSelectType} 
                                        dataCode={dataCode} 
                                        setDataCode={setDataCode} 
                                        dataCodeOther={dataCodeOther} 
                                        setDataCodeOther={setDataCodeOther} 
                                        reandonMakeId={reandonMakeId} 
                                        checkNumberRate={checkNumberRate} 
                                        alertTxt={alertTxt}
                                        scrollRef={scrollRef}
                                        statusOpen={statusOpen}
                                    ></TypeLottory>

                                    {
                                        (checkRound !== 0 && checkRound !== null) && (
                                            statusOpen && (
                                                selectType !== null && (
                                                    <div className={styles.boxMarginTop}>
                                                        <InputNumber selectType={selectType} dataCode={dataCode} dataCodeOther={dataCodeOther}  reandonMakeId={reandonMakeId} numberRate={numberRate} checkNumberRate={checkNumberRate} alertTxt={alertTxt}></InputNumber>
                                                    </div>
                                                )
                                            )
                                        )
                                    }
                                </div>

                                <div className={styles.boxMarginTop}>
                                    <Condition typeData={typeData}></Condition>
                                </div>

                                <Footer CardContent={CardContent} lottery_code={lottery_code}></Footer>
                            
                            </>
                        }
                        </>
                        
                    }
                </>
                :
                <>
                    {
                        lottoCode === "HYK" ?
                        <div className={styles.boxPaddingBottom}>
                            <div className={styles.boxMarginTop}>
                                
                                <CardContent boxType="radius">
                                    <TimeOut lottoryData={lottoryData} checkRound={checkRound} statusOpen={statusOpen} roundStatus={roundStatus}></TimeOut>
                                </CardContent>

                            </div>

                            <div className={styles.boxMarginTop}>
                                {/* <YikiReward roundData={yikiResultId} lottoData={lottoryData}></YikiReward> */}
                                <YikiReward lottoData={lottoryData}></YikiReward>
                            </div>
                        </div>
                        :
                        <>
                        <div className={styles.boxMarginTop}>
                        
                            <CardContent boxType="radius">
                                <TimeOut lottoryData={lottoryData} checkRound={checkRound} statusOpen={statusOpen}></TimeOut>
                            </CardContent>

                        </div>
                            <div className={styles.boxMarginTop} ref={scrollRef}>
                                <TypeLottory 
                                    typeData={typeData} 
                                    selectType={selectType} 
                                    setSelectType={setSelectType} 
                                    dataCode={dataCode} 
                                    setDataCode={setDataCode} 
                                    dataCodeOther={dataCodeOther} 
                                    setDataCodeOther={setDataCodeOther} 
                                    reandonMakeId={reandonMakeId} 
                                    checkNumberRate={checkNumberRate} 
                                    alertTxt={alertTxt}
                                    scrollRef={scrollRef}
                                    statusOpen={statusOpen}
                                ></TypeLottory>

                                {
                                    (checkRound !== 0 && checkRound !== null) && (
                                        statusOpen && (
                                            selectType !== null && (
                                                <div className={styles.boxMarginTop}>
                                                    <InputNumber selectType={selectType} dataCode={dataCode} dataCodeOther={dataCodeOther}  reandonMakeId={reandonMakeId} numberRate={numberRate} checkNumberRate={checkNumberRate} alertTxt={alertTxt}></InputNumber>
                                                </div>
                                            )
                                        )
                                    )
                                }
                            </div>

                            <div className={styles.boxMarginTop}>
                                <Condition typeData={typeData}></Condition>
                            </div>

                            <Footer CardContent={CardContent} lottery_code={lottery_code}></Footer>
                        
                        </>
                    }
                </>
            }
            

            
        </>
    );
}