import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux
import { useSelector } from 'react-redux';

// Component
import {
    Tabs,
    Tab,
    Typography,
    Box
} from '@mui/material';
// import CardContent from "views/components/CardContent";
import ReportNotReleased from "views/pages/order-list/component/ReportNotReleased";
import ReportReleased from "views/pages/order-list/component/ReportReleased";
import ResultModal from "views/pages/order-list/component/ResultModal";
import AlertModal from "views/components/Alert/index";
import CardContent from 'views/components/CardContent';

// Icon
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

// CSS
import styles from "assets/css/Style.module.css";
import orderListStyle from "views/pages/order-list/assets/OrderList.module.css";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function ContentOrderList({ loadData }) {
    const reduxOrderList = useSelector(state => state.reduxOrderList.result);
    const reduxResultNumber = useSelector(state => state.reduxOrderList.resultNumber);
    const [value, setValue] = useState(0);
    const [countRow, setCountRow] = useState(0);
    const [nameTitle, setNameTitle] = useState("");
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const [resultModal, setResultModal] = useState({
        show: false
    }); 

    const [alertData, setAlertData] = useState({
        title: "",
        show: false,
        status: "",
        message: "",
        type: "",
        action: "",
        confirmText: "",
        cancelText: "",
        opacityClose: "",
        opacity: "",
        confirmStatus: false,
        cancelStatus: false
    }); 

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {
                resultModal.show && (
                    <ResultModal resultModal={resultModal} nameTitle={nameTitle} setResultModal={setResultModal} dataList={reduxResultNumber}></ResultModal>
                )
            }

            {
                alertData.show && (
                    <AlertModal alertData={alertData} setAlertData={setAlertData}></AlertModal>
                )
            }

            <div className={styles.boxMarginTop}>
                <div className={styles.root}>
                    <CardContent>
                        <Tabs
                            variant="fullWidth"
                            value={value}
                            onChange={handleChange}
                            classes={{
                                indicator: styles.indicator
                            }}
                        >
                            <LinkTab label="โพยที่ยังไม่ออกผล" className={value === 0 ? styles.tabTextActive : styles.tabText} icon={<AssignmentIcon className={styles.tabIconSize} />} {...a11yProps(0)} />
                            <LinkTab label="โพยที่ออกผลแล้ว" className={value === 1 ? styles.tabTextActive : styles.tabText} icon={<AssignmentTurnedInIcon className={styles.tabIconSize} />} {...a11yProps(1)} />
                        </Tabs>
                    </CardContent>

                    <TabPanel value={value} index={0} className={styles.tabContent}>
                        <ReportNotReleased isLargeScreen={isLargeScreen} reduxOrderList={reduxOrderList} setCountRow={setCountRow} setResultModal={setResultModal} setNameTitle={setNameTitle} alertData={alertData} setAlertData={setAlertData} loadData={loadData} />
                    </TabPanel>
                    <TabPanel value={value} index={1} className={styles.tabContent}>
                        <ReportReleased isLargeScreen={isLargeScreen} reduxOrderList={reduxOrderList} setCountRow={setCountRow} setResultModal={setResultModal} setNameTitle={setNameTitle} alertData={alertData} setAlertData={setAlertData} loadData={loadData} />
                    </TabPanel>

                    <div className={orderListStyle.loadMoreAlign}>
                        {
                            reduxOrderList !== null && (
                                reduxOrderList.data !== null && (
                                    reduxOrderList.data.length > 0 && (
                                        countRow >= 0 && (
                                            reduxOrderList.data.length < reduxOrderList.data_total && (
                                                <div className="center">
                                                    <div className={orderListStyle.loadMore} onClick={() => loadData((reduxOrderList.data.length + 10), -1)}>
                                                        โหลดข้อมูลเพิ่มเติม
                                                    </div>
                                                </div>
                                            )
                                        )
                                    )
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}