const SET_DATA_NAVBAR_SUB = 'app/SET_DATA_NAVBAR_SUB';

function setDataNavbarSub(data) {
    return {
        type: SET_DATA_NAVBAR_SUB,
        payload: {
            items: data
        }
    }
}

export {
    SET_DATA_NAVBAR_SUB,
    setDataNavbarSub
}