// Hooks
import useParameterUrl from "@utility/hooks/useParameterUrl";
import useEffectOnce from "@utility/hooks/useEffectOnce";

// Redux
import { useDispatch } from 'react-redux';
import { setDataNavbarSub } from "@core/redux/actions/app/navbarSub";

// Component
import ContentBasket from "./component/Content";

const BasketApp = () => {
    window.scrollTo(0, 0);
    const dispatch = useDispatch();
    const parsed = useParameterUrl();
    const { 
      code
    } = parsed;

    useEffectOnce(() => {
        dispatch(setDataNavbarSub({
            typeContent: "navbarBack",
            textContent: "ตะกร้าโพยหวย",
            redirect: `/lottory/${code}`,
            condition: false
        }));
    }, [dispatch]);

    return (
        <ContentBasket lottery_code={code}></ContentBasket>
    );
}

export default BasketApp;