function regexFormat(data, type) {
    let result = false;
    if(type === "number") {
        result = /^-?[\d.]+(?:e-?\d+)?$/.test(data); 
    }
    else {
        result = null;
    }

    return result; 
}

export default regexFormat;