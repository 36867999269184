// Services
import { apiServices } from "@core/services/appService";

// Redux Action
import { SET_LOADDING } from "@core/redux/actions/app/loadding";

const SET_DATA_ORDER_LIST = 'app/SET_DATA_ORDER_LIST';
const SET_RESULT_NUMBER = 'app/SET_RESULT_NUMBER';

function loadOrderList(myJson, token) {
    return dispatch => {
        dispatch({
            type: SET_LOADDING,
            payload: {
                items: {
                    show: true,
                    textProcess: null
                }
            }
        });

        apiServices(myJson, "post", "/py/lottery/ticket", token).then((data) => {
            dispatch({
                type: SET_LOADDING,
                payload: {
                    items: {
                        show: false,
                        textProcess: null
                    }
                }
            });

            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 1) {
                const result = data.result;
                
                dispatch({
                    type: SET_DATA_ORDER_LIST,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_DATA_ORDER_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

function loadResultNumber(myJson, token) {
    return dispatch => {
        dispatch({
            type: SET_LOADDING,
            payload: {
                items: {
                    show: true,
                    textProcess: null
                }
            }
        });

        dispatch({
            type: SET_RESULT_NUMBER,
            payload: {
                items: []
            }
        });

        apiServices(myJson, "post", "/py/lottery/ticket/number", token).then((data) => {
            const statusCode = data.code;
            dispatch({
                type: SET_LOADDING,
                payload: {
                    items: {
                        show: false,
                        textProcess: null
                    }
                }
            });

            if (statusCode === 200 || statusCode === 1) {
                const result = data.result;
                
                dispatch({
                    type: SET_RESULT_NUMBER,
                    payload: {
                        items: result
                    }
                });
            }
            else {
                dispatch({
                    type: SET_RESULT_NUMBER,
                    payload: {
                        items: null
                    }
                });
            }
        });
    }
}

export {
    loadOrderList,
    loadResultNumber,
    SET_DATA_ORDER_LIST,
    SET_RESULT_NUMBER
}