import { useState, useEffect, useCallback } from 'react';

// @Utility
import regexFormat from "@utility/app/regexFormat";
import lotteryFormat from "@utility/app/lotteryFormat";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setBasketData } from "@core/redux/actions/basket/index";

// Component
import Grid from '@mui/material/Grid';

// Icon
import BackspaceIcon from '@mui/icons-material/Backspace';

// CSS
import styles from "assets/css/Style.module.css";
import lottoryStyle from "views/pages/lottory/assets/Lottory.module.css";

export default function InputNumber({ selectType, dataCode, dataCodeOther, reandonMakeId, checkNumberRate, alertTxt }) {
    const dataNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 99, 0, 101];
    const [inputLoop, setInputloop] = useState([]);
    const [inputLoopBackup, setInputLoopBackup] = useState(0);
    const [rowInput, setRowInput] = useState(0); 
    const lottoryData = useSelector(state => state.reduxBasket.lottory);
    const dispatch = useDispatch();

    const handleNumber = (value) => {
        setNumberArr(value);
    };

    const checkNumberMax = (item, number) => {
        // let dataArr = [];
        // dataArr = lottoryData.filter((element) => (element.code === item.code && element.number === number));
        
        // if(dataArr.length > 0) {
        //     let dataIndex = dataArr[0];
        //     let dataCount = dataArr.length;

        //     if(dataCount >= dataIndex.max_number) {
        //         alertTxt("แจ้งเตือน", true, "warning", `${item.name} จำนวนตัวเลข ${number} เกินค่าสูงสุด ไม่สามารถเพิ่มเข้าตะกร้าได้`);
        //         return false;
        //     }
        //     else {
        //         return true;
        //     }
        // }
        // else {
        //     return true;
        // }
        return true;
    };

    const setNumberArr = (keyNumber) => {
        // let dataArr = [...inputLoop, keyNumber];
        // let rowCount = inputLoopBackup;
        // let dataKeyboard = [];
        // dataArr.shift();
        // setInputloop(dataArr);
        // setInputLoopBackup(--rowCount);

        let dataArr = inputLoop;
        let dataKeyboard = [];
        for(let i = 0; i < dataArr.length; i++) {
            if(dataArr[i] === null) {
                dataArr[i] = keyNumber;
                break;
            }
        }

        let rowCount = dataArr.filter((item) => ( item !== null ));
        setInputloop(dataArr);
        setInputLoopBackup(rowCount.length);

        // console.log(inputLoop)

        if(rowCount.length === inputLoop.length) {
            if(dataCode !== null) {
                let dataNumber = [];
                let statusError = false;
                dataKeyboard = dataArr.filter((item) => (item !== null));
                dataCode.forEach(element => {
                    if(dataCodeOther.length > 0) {
                        dataCodeOther.forEach((element2) => {
                            let numberLottory2 = lotteryFormat(dataKeyboard, element2.code, element2.length);
                            numberLottory2.forEach((number) => {
                                let lottoryMax = checkNumberMax(element2, number);
                                if(lottoryMax) {
                                    let lottoryRate = checkNumberRate(element2, number);
                                    if(lottoryRate !== false) {
                                        if(lottoryRate.price > 0) {
                                            statusError = false;
                                            dataNumber = [...dataNumber, {
                                                ...element,
                                                number: number, 
                                                bet: element.min,
                                                key: reandonMakeId(3),
                                                price: lottoryRate.price,
                                                is_cancel: false,
                                                is_max: false,
                                                is_max_number: false,
                                                is_min: false,
                                                is_price_rate: false
                                            }];
                                        }
                                        else {
                                            statusError = true;
                                            alertTxt("แจ้งเตือน", true, "warning", `ปิดตัวเลข ${number}`);
                                        }
                                    }
                                    else {
                                        statusError = false;
                                        dataNumber = [...dataNumber, {
                                            ...element,
                                            number: number, 
                                            bet: element.min,
                                            key: reandonMakeId(3),
                                            is_cancel: false,
                                            is_max: false,
                                            is_max_number: false,
                                            is_min: false,
                                            is_price_rate: false
                                        }];
                                    }
                                }
                            });
                        });
                    }
                    else {
                        let numberLottory = lotteryFormat(dataKeyboard, element.code, element.length);
                        let lottoryMax = checkNumberMax(element, numberLottory[0]);
                        if(lottoryMax) {
                            let lottoryRate = checkNumberRate(element, numberLottory[0]);

                            if(lottoryRate !== false) {
                                if(lottoryRate.price > 0) {
                                    statusError = false;
                                    dataNumber = [...dataNumber, {
                                        ...element,
                                        number: numberLottory[0], 
                                        bet: element.min,
                                        key: reandonMakeId(3),
                                        price: lottoryRate.price,
                                        is_cancel: false,
                                        is_max: false,
                                        is_max_number: false,
                                        is_min: false,
                                        is_price_rate: false
                                    }];
                                }
                                else {
                                    statusError = true;
                                    alertTxt("แจ้งเตือน", true, "warning", `ปิดตัวเลข ${numberLottory[0]}`);
                                }
                            }
                            else {
                                statusError = false;

                                dataNumber = [...dataNumber, {
                                    ...element,
                                    number: numberLottory[0], 
                                    bet: (element.min > 0 ? 1 : 0),
                                    key: reandonMakeId(3),
                                    is_cancel: false,
                                    is_max: false,
                                    is_max_number: false,
                                    is_min: false,
                                    is_price_rate: false
                                }];
                            }
                        }
                    }
                });

                if(statusError === false) {
                    alertTxt("แจ้งเตือน", true, "success", `เพิ่มตัวเลข ${dataNumber.length} รายการ`);
                    dispatch(setBasketData([...lottoryData, ...dataNumber]));
                }
            }

            setTimeout(() => {
                defaultDataInput();
            }, 400);
        }
    };

    const keyboardFunction = useCallback((event) => {
        let keyData = event.key;
        // console.log(keyData);
        if(keyData === "Backspace") {
            removeNumber();
        }
        else {
            if(keyData !== ".") {
                if(regexFormat(keyData, "number")) {
                    let keyNumber = Number(keyData);
                    setNumberArr(keyNumber);
                }
            }
        }
    }, [inputLoop]);

    const defaultDataInput = () => {
        let arrLoop = [];
        for(let i = 0; i < rowInput; i++) {
            arrLoop = [...arrLoop, null];
        }

        setInputloop(arrLoop);
        setInputLoopBackup(rowInput);
    };

    const renderInput = (item) => {
        if(item === 99) {
            return (
                <div key={item} className={[lottoryStyle.letter, lottoryStyle.letterCancel].join(" ")} onClick={cancelAll}>
                    ยกเลิกล่าสุด
                </div>
            );
        }
        else if(item === 99 || item === 101) {
            return (
                <div key={item} className={[lottoryStyle.letter, lottoryStyle.letterDelete].join(" ")} onClick={removeNumber}>
                    <BackspaceIcon className={lottoryStyle.iconDeleteSize}></BackspaceIcon>
                </div>
            );
        }
        else {
            if(item === 4 || item === 7) {
                return (
                    <div key={item} className={[lottoryStyle.letter, lottoryStyle.clearl].join(" ")} onClick={() => handleNumber(item)}>
                        {item}
                    </div>
                );
            }
            else {
                return (
                    <div key={item} className={lottoryStyle.letter} onClick={() => handleNumber(item)}>
                        {item}
                    </div>
                );
            }
        }
    };

    const renderPushNumber = (item, index) => {
        if(index === 0) {
            if(item === null) {
                return (
                    <div className={[lottoryStyle.boxNumber, "relative"].join(" ")}>
                        {/* <div className="relative"> */}
                            <span className={lottoryStyle.boxNumberBorder}></span>
                        {/* </div> */}
                    </div>
                );
            }
            else {
                return (
                    <div className={lottoryStyle.boxNumber}>
                        {item}
                    </div>
                );
            }
        }
        else {
            return (
                <div className={lottoryStyle.boxNumber}>
                    {item}
                </div>
            );
        }
    };

    const cancelAll = () => {
        defaultDataInput();
    };

    const removeNumber = () => {
        let dataArrCheck = inputLoop.filter((item) => (item !== null));
        
        if(dataArrCheck.length > 0) {
            let dataArr = inputLoop;
            const index = dataArrCheck.length - 1;
            dataArr[index] = null;
            setInputloop(dataArr);
            setInputLoopBackup(index);
        }

        // let dataArrCheck = inputLoop.filter((item) => (item !== null));
        
        // if(dataArrCheck.length > 0) {
        //     let rowCount = inputLoopBackup;
        //     let dataArr = [null, ...inputLoop];
        //     dataArr.pop();
        //     setInputLoopBackup(++rowCount);
        //     setInputloop(dataArr);
        // }
    };

    useEffect(() => {
        document.addEventListener("keydown", keyboardFunction, false);

        return () => {
            document.removeEventListener("keydown", keyboardFunction, false);
        };
    }, [inputLoop]);

    useEffect(() => {
        defaultDataInput();
    }, [rowInput]);

    useEffect(() => {
        setRowInput(selectType);
    }, [selectType]);
    
    // lotteryFormat([5, 4, 7], "three_change");
    
    return (
        <>
            <div className="center">
                <Grid container spacing={2} justifyContent="center">
                    {
                        inputLoop.map((data, index) => (
                            <Grid key={index} item>
                                {
                                    renderPushNumber(data, index)
                                }
                            </Grid>
                        ))
                    }
                </Grid>
            </div>

            <div className={styles.boxMarginTop}>
                <div className={lottoryStyle.keyboard}>
                    {
                        dataNumber.map((item) => (
                            renderInput(item)
                        ))
                    }
                </div>
            </div>
        </>
    );
}