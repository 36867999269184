import { useState, useEffect, useCallback, useMemo } from 'react';

// @Utility
import regexFormat from "@utility/app/regexFormat";

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Component
import { Grid, Typography } from '@mui/material';

// Icon
import BackspaceIcon from '@mui/icons-material/Backspace';

// CSS
import styles from "assets/css/Style.module.css";
import lottoryStyle from "views/pages/lottory/assets/Lottory.module.css";
import { apiServices } from '@core/services/appService';
import moment from 'moment'

import CardContent from 'views/components/CardContent'

import { loadYikiShootAll } from '@core/redux/actions/yiki/index'

// CSS
import orderListStyle from 'views/pages/order-list/assets/OrderList.module.css'

import useEffectOnce from '@utility/hooks/useEffectOnce';

export default function ShootNumber({ lottoData, dataCode,roundData, alertTxt }) {
    const dataNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 99, 0, 101];
    const [inputLoop, setInputloop] = useState([]);
    const [inputLoopBackup, setInputLoopBackup] = useState(0);
    const [rowInput, setRowInput] = useState(0); 
    const dispatch = useDispatch();
    const [waitTen, setWaitTen] = useState(false);
    const processing = useSelector(state => state.reduxHome.processing);
    const reduxYiki = useSelector((state) => state.reduxYiki)
    const reduxAuthData = useSelector(state => state.reduxAuth.result);
    const reduxLottoryData = useSelector(state => state.reduxLottory.result);
    const reduxAuth = {...reduxAuthData, ...reduxLottoryData};

    const handleNumber = (value) => {
        if (waitTen === true) {
            return
        } else {
            setNumberArr(value);
        }
        
    };

    

    // กดเลขครบแล้วเพิ่มลงตะกร้า
    const setNumberArr = (keyNumber) => {
        let dataArr = inputLoop;
        let dataKeyboard = [];
        for(let i = 0; i < dataArr.length; i++) {
            if(dataArr[i] === null) {
                dataArr[i] = keyNumber;
                break;
            }
        }

        let rowCount = dataArr.filter((item) => ( item !== null ));
        setInputloop(dataArr);
        setInputLoopBackup(rowCount.length);

        if(rowCount.length === inputLoop.length) {
            if(dataCode !== null) {
                dataKeyboard = dataArr.filter((item) => (item !== null));
                
                if (dataKeyboard !== []) {
                    setWaitTen(true);
                    setTimeout(countDown, 1000);
                                        
                    let myJson = {
                        "round_id": lottoData.round_id,
                        "number": dataKeyboard.join(""),
                        "send_type": "YIKI"
                    }

                    apiServices(myJson, "post", "/py/lottery/yiki/shoot-number", reduxAuth?.token).then((data) => {
                        const statusCode = data.code;
                        // const result = data.result;

                        if (statusCode === 200 || statusCode === 1) {
                            alertTxt("แจ้งเตือน", true, "success", `ยิงเลข ${dataKeyboard.join("")} แล้ว`);
                        } else if (data?.message === undefined) {
                            
                            alertTxt("แจ้งเตือน", true, "warning", " เพิ่มเลขไม่ได้ เนื่องจากเกินจำนวนครั้งต่อนาที ");
                        } else {
                            const message = data?.message;
                            alertTxt("แจ้งเตือน", true, "warning", ` ${message} `);
                        }
                        // console.log(data?.message)
                        dispatch(loadYikiShootAll(roundId, reduxAuth?.token))
                        
                    });
                }
            }

            setTimeout(() => {
                defaultDataInput();
                setWaitTen(false);
            }, 10000);
        }
    };

    const keyboardFunction = useCallback((event) => {
        let keyData = event.key;
        if(keyData === "Backspace") {
            removeNumber();
        }
        else {
            if(keyData !== ".") {
                if(regexFormat(keyData, "number")) {
                    let keyNumber = Number(keyData);
                    setNumberArr(keyNumber);
                }
            }
        }
    }, [inputLoop]);

    const defaultDataInput = () => {
        let arrLoop = [];
        for(let i = 0; i < rowInput; i++) {
            arrLoop = [...arrLoop, null];
        }
        timeLeft = 10;
        setInputloop(arrLoop);
        setInputLoopBackup(rowInput);
    };

    // เรนเดอร์ปุ่มกดตัวเลข
    const renderInput = (item) => {
        if(item === 99) {
            return (
                <div key={item} className={[lottoryStyle.letter, lottoryStyle.letterCancel].join(" ")} onClick={cancelAll}>
                    ยกเลิกล่าสุด
                </div>
            );
        }
        else if(item === 99 || item === 101) {
            return (
                <div key={item} className={[lottoryStyle.letter, lottoryStyle.letterDelete].join(" ")} onClick={removeNumber}>
                    <BackspaceIcon className={lottoryStyle.iconDeleteSize}></BackspaceIcon>
                </div>
            );
        }
        else {
            if(item === 4 || item === 7) {
                return (
                    <div key={item} className={[lottoryStyle.letter, lottoryStyle.clearl].join(" ")} onClick={() => handleNumber(item)}>
                        {item}
                    </div>
                );
            }
            else {
                return (
                    <div key={item} className={lottoryStyle.letter} onClick={() => handleNumber(item)}>
                        {item}
                    </div>
                );
            }
        }
    };

    // เรนเดอร์ช่องใส่ตัวเลข
    const renderPushNumber = (item, index) => {
        if(index === 0) {
            if(item === null) {
                return (
                    <div className={[lottoryStyle.boxNumber, "relative"].join(" ")}>
                        {/* <div className="relative"> */}
                            <span className={lottoryStyle.boxNumberBorder}></span>
                        {/* </div> */}
                    </div>
                );
            }
            else {
                return (
                    <div className={lottoryStyle.boxNumber}>
                        {item}
                    </div>
                );
            }
        }
        else {
            return (
                <div className={lottoryStyle.boxNumber}>
                    {item}
                </div>
            );
        }
    };

    // ล้างข้อมูล
    const cancelAll = () => {
        defaultDataInput();
    };

    // ลบเลขทีละตัว
    const removeNumber = () => {
        let dataArrCheck = inputLoop.filter((item) => (item !== null));
        
        if(dataArrCheck.length > 0) {
            let dataArr = inputLoop;
            const index = dataArrCheck.length - 1;
            dataArr[index] = null;
            setInputloop(dataArr);
            setInputLoopBackup(index);
        }
    };

    useEffect(() => {
        if (waitTen === true) {
            return
        } else {
            document.addEventListener("keydown", keyboardFunction, false);
        }
        

        return () => {
            document.removeEventListener("keydown", keyboardFunction, false);
        };
    }, [inputLoop, keyboardFunction, waitTen]);

    useEffect(() => {
        defaultDataInput();
    }, [rowInput]);

    useEffect(() => {
        setRowInput(5);
    }, []);

    // useEffect(() => {
    //     if (waitTen === true) {
    //         timeLeft > 0 && setTimeout(() => setTimeLeft(timeLeft -1), 1000)
    //         console.log(timeLeft)
    //     } else {
    //         setTimeLeft(10)
    //     }
        
    // }, [timeLeft])
    
    // lotteryFormat([5, 4, 7], "three_change");

    let timeLeft = 10;

    const countDown = () => {
        --timeLeft;
        document.getElementById("timecount").innerHTML = String( timeLeft );
        if (timeLeft > 0) {
            // setTimeLeft(timeLeft - 1);
            setTimeout(countDown, 1000);
        }
    };

    // const countDown = () => {
    //     setTimeCountTen(setInterval(() => {
    //         --timeLeft;
    //         if (timeLeft <= 0) {
    //             clearInterval(timeCountTen);
    //             setTimeLeft(0);
    //         }
    //     }, 1000));

    //     return () => {
    //         clearInterval(setTimeCountTen);
    //         timeLeft
    //     }
    // };

    const roundId = lottoData.round_id
    // console.log(roundId)

    useEffect(() => {
        dispatch(loadYikiShootAll(roundId, reduxAuth?.token))
        // console.log(reduxYiki)
    }, [processing,dispatch])

    // console.log(roundId)

    // dispatch(loadYikiShootAll(roundId, reduxAuth?.token))

    
    // useEffect(() => {
        
    //     if (reduxYiki !== null) {
    //         if (reduxYiki.length > 0) {
    //             // console.log(reduxYiki) 
    //             const ykl = [...reduxYiki.result]
    //             console.log(ykl)
    //             // setYikiShootList(reduxYiki.result)
    //             // dispatch(loadYikiShootAll(roundId, reduxAuth?.token))
    //             // const checkTime = setInterval(() => {
    //             //     dispatch(loadYikiShootAll(roundId, reduxAuth?.token))
    //             //     clearInterval(checkTime)
    //             // }, 3000);

    //             // setCheckTimeout(checkTime);

    //             // return () => {
    //             //     clearInterval(checkTime);
    //             // }
    //         } 
    //         // else {
    //         //     if (checkTimeout !== null) {
    //         //         clearInterval(checkTimeout)
    //         //     }
    //         // }
    //     } else {
    //         console.log(reduxYiki)
    //     }
    // }, [reduxYiki])

    useEffect(() => {
        // console.log(reduxYiki)
        const timer = setTimeout(() => {
            dispatch(loadYikiShootAll(roundId, reduxAuth?.token))
        }, 5000);
        return () => clearTimeout(timer);
    }, [processing, reduxYiki, dispatch])

    // console.log(reduxYiki)

    
    return (
        <>
            <div className="center">
                <div className={styles.boxMarginTop}>
                    <span style={{color: "val(--bg-black)", fontSize: "30px"}}>
                        { inputLoop.join("") }
                    </span>
                </div>
                <Typography component={"span"} variant={'h6'} style={{ color: "val(--bg-black)" }}>ป้อนตัวเลข</Typography>
                <div className={styles.boxMarginTop}>
                    {/* {console.log(timeLeft)} */}
                {
                    waitTen ? 
                        <Typography component={"span"} variant={'h6'} className="textCLDanger">
                            รอ{' '}
                            <Typography component={"span"} variant={"h6"} id="timecount">
                                10
                            </Typography>
                            {/* <span dangerouslySetInnerHTML={{ __html: String() }}></span> */}
                            {' '}วินาที
                        </Typography>
                    :
                    <Grid container spacing={1} justifyContent="center">
                        {/* <Typography component={"span"} variant={"h6"} id="timecount" hidden>
                                &nbsp;
                            </Typography> */}
                    {
                        inputLoop.map((data, index) => (
                            <Grid key={index} item>
                                {
                                    renderPushNumber(data, index)
                                }
                            </Grid>
                        ))
                    }
                    </Grid>
                }
                </div>
                
            </div>

            <div className={styles.boxMarginTop}>
                <div className={lottoryStyle.keyboard}>
                    {
                        dataNumber.map((item) => (
                            renderInput(item)
                        ))
                    }
                </div>
            </div>

            <div className={styles.boxMarginTop}>
            <CardContent>
                <div
                    className={[lottoryStyle.shootListTitle, 'textBoldMedium'].join(' ')}
                >
                    รายชื่อผู้ทายเลข
                </div>
                <table className={orderListStyle.tableSize}>
                    <tbody>
                        {/* {console.log(yikiShootList)} */} 
                        {reduxYiki.result !== null &&
                            reduxYiki.result.length > 0 ? (
                                reduxYiki.result.map((item, index) => (
                                    <tr
                                        className={[
                                            orderListStyle.tableListPopup,
                                            lottoryStyle.tableListReward,
                                            lottoryStyle.tableBorderBottom
                                        ].join(' ')}
                                        key={index}
                                    >
                                        <td style={{ textAlign: 'center' }}>
                                            <span
                                                className={
                                                    index === 0 || index === 15 || index === 99
                                                        ? lottoryStyle.shootListFirst
                                                        : lottoryStyle.shootListOrder
                                                }
                                            >
                                                ลำดับที่ {index + 1}
                                            </span>
                                            <br />
                                            {item.number}
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <span className={lottoryStyle.tableDataHead}>ชื่อผู้ใช้</span> <br />
                                            {item.customer_username}
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <span className={lottoryStyle.tableDataHead}>วันที่/เวลา</span>
                                            <br />
                                            {moment(item.time_create).format(
                                                'yyyy-MM-DD HH:mm:ss'
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr
                                    className={[
                                        orderListStyle.tableListPopup,
                                        lottoryStyle.tableListPopup,
                                    ].join(' ')}
                                >
                                    <td style={{ textAlign: 'center' }}>
                                        <span className={lottoryStyle.tableDataHead}>ไม่มีข้อมูล</span>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
                <br />
            </CardContent>
            </div>
        </>
    );
}