import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { useNavigate } from 'react-router';

// @Utility
import setBodyScroll from "@utility/app/setBodyScroll";

// Redux
// import { useSelector } from 'react-redux';

// Component
import Grid from '@mui/material/Grid';
import ModalFull from "views/components/ModalFull";

// CSS
import navbarStyle from "assets/css/Navbar.module.css";
import modalStyles from "assets/css/Modal.module.css";
import orderListStyle from "views/pages/order-list/assets/OrderList.module.css";

// Icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Condition = ({ modalData, setModalData, reduxAuth }) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    // const reduxAppSetting = useSelector(state => state.reduxAppSetting);
    // const history = useNavigate();

    const onShow = (val) => {
        setBodyScroll(val);
        setModalData({ ...modalData, show: val });
    };

    return (
        <ModalFull show={modalData.show} onShow={onShow} bgClass={1}>
            {
                reduxAuth !== null && (
                    <>
                        {
                            isLargeScreen ?
                            <div className={modalStyles.conditionTitle}>
                                {reduxAuth?.lottery?.name}
                            </div>
                            :
                            <div className={modalStyles.conditionTitleMobile}>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <div className={navbarStyle.cursorBack} onClick={() => onShow(false)} style={{ color: "white" }}>
                                            <span className={navbarStyle.cursorBackAlign}>
                                                <ArrowBackIcon className={navbarStyle.backIcon}></ArrowBackIcon> 
                                            </span>

                                            ย้อนกลับ
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="center">
                                            {reduxAuth?.lottery?.name}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        <div className={modalStyles.conditionBody}>
                            <div dangerouslySetInnerHTML={{ __html: reduxAuth?.lottery?.policy }}></div>
                        </div>
                        <div className={modalStyles.conditionMaginTop}>
                            <div className={modalStyles.conditionHead}>
                                อัตราการจ่าย
                            </div>
                            <div className={[modalStyles.conditionBody, modalStyles.conditionBodyRadius, modalStyles.conditionBodyPadding].join(" ")}>
                                <table className={orderListStyle.tableConditionSize}>
                                    <thead>
                                        <tr>
                                            <th className={[orderListStyle.theadConditionSize, modalStyles.theadSize].join(" ")} style={{ textAlign: "center" }}>ประเภทหวย</th>
                                            <th className={[orderListStyle.theadConditionSize, modalStyles.theadSize].join(" ")} style={{ textAlign: "center" }}>ราคาจ่าย</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            reduxAuth?.lottery_type !== undefined && (
                                                reduxAuth.lottery_type.map((item, index) => (
                                                    <tr key={index} className={[orderListStyle.tableList, modalStyles.tableList, (index % 2 === 0) ? orderListStyle.listGrey : orderListStyle.listDefault].join(" ")}>
                                                        <td style={{ textAlign: "center", fontWeight: "600" }}>
                                                            {
                                                                item.name
                                                            }
                                                        </td>
                                                        <td style={{ textAlign: "center", fontWeight: "600" }}>
                                                            {
                                                                item.price
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )
            }
        </ModalFull>
    );
}

export default Condition;