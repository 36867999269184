import { 
    SET_LOAD_HOME_LIST,
    SET_LOAD_HOME_PROCESSING
} from "@core/redux/actions/home/index";

const initialState = {
    result: null,
    processing: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOAD_HOME_LIST:
            return { ...state, result: action.payload.items };
        case SET_LOAD_HOME_PROCESSING:
            return { ...state, processing: action.payload.items };
        default:
            return state;
    }
}