// Component
import ContentError from "./component/Content";

const ErrorApp = ({ reduxAuth }) => {
    window.scrollTo(0, 0);
    
    return (
        <ContentError reduxAuth={reduxAuth}></ContentError>
    );
}

export default ErrorApp;