// CSS
import styles from "assets/css/Style.module.css";

export default function CardContent(props) {

    return (
        <div className={props.boxType === "radius" ? styles.boxContentRadius : styles.boxContent} style={props.style}>
            {
                props.children
            }
        </div>
    );
}