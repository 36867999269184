import { useEffect, useState } from 'react';

// @Utility
import currencyFormat from "@utility/numbers/currencyFormat";
import timeZone from "@utility/app/timeZone";
import dateFormatThai from "@utility/app/dateFormatThai";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { loadResultNumber } from "@core/redux/actions/orderList/index";
import { setLoadding } from "@core/redux/actions/app/loadding";
import { setAuthenBalance } from "@core/redux/actions/auth/index";

// Component
import { Grid } from "@mui/material";

// Services
import { apiServices } from "@core/services/appService";

// Icon
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ReplayIcon from '@mui/icons-material/Replay';

// CSS
import orderListStyle from "views/pages/order-list/assets/OrderList.module.css";

export default function ListGrid({ bodyData, typeTable, setResultModal, setNameTitle,  setAlertData, alertData, loadData, isLargeScreen }) {
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const reduxOrderList = useSelector(state => state.reduxOrderList.result);
    const dispatch = useDispatch();
    const [ticketId, setTicketId] = useState(null);

    const openResult = (item) => {
        let myJson = {
            "filter": {
                "ticket_id": item.id,
                "round_id": item.round_id
            }
        };
        
        setNameTitle(`${item.lottery_name} ${item.ticket}`);
        dispatch(loadResultNumber(myJson, reduxAuth?.token));
        setResultModal({show: true});
    };

    const renderStatus = ({ status }) => {
        if(status === 5) {
            return (
                <span className="label labelFontSize labelSuccess" style={{ marginLeft: "0px" }}>
                    ออกผล
                </span>
            );
        }
        else if(status === 6) {
            return (
                <span className="label labelFontSize labelDanger" style={{ marginLeft: "0px" }}>
                    ยกเลิก
                </span>
            );
        }
        else if(status === 7) {
            return (
                <span className="label labelFontSize labelInfo" style={{ marginLeft: "0px" }}>
                    คืนโพย
                </span>
            );
        }
        else {
            return (
                <span className="label labelFontSize labelWarning" style={{ marginLeft: "0px" }}>
                    รอผล
                </span>
            );
        }
    };

    const cencelOrder = (item) => {
        setTicketId(item.id);

        setAlertData({
            title: "แจ้งเตือน",
            show: true,
            status: "warning",
            message: "คุณต้องการยกเลิกโพยใช่ หรือไม่ ?",
            type: "text",
            action: "sayYes",
            confirmText: "ใช่ ยกเลิกโพย",
            cancelText: "ไม่ ย้อนกลับ",
            cancelStatus: false,
            opacityClose: true,
            opacity: true
        });
    };

    const requestApi = (ticket_id) => {
        dispatch(setLoadding({
            show: true,
            textProcess: "กำลังยกเลิกโพย กรุณารอสักครู่..."
        }));

        let myJson = {
            "filter": {
                "ticket_id": ticket_id
            }
        };

        apiServices(myJson, "post", "py/lottery/ticket/cancel", reduxAuth?.token).then((data) => {
            const statusCode = data.code;
            const result = data.result;
            dispatch(setLoadding({
                show: false,
                textProcess: null
            }));

            if (statusCode === 200 || statusCode === 1) {
                setAlertData({
                    title: "แจ้งเตือน",
                    show: true,
                    status: "success",
                    message: "ยกเลิกโพยหวยสำเร็จ !",
                    type: "text",
                    action: "confirm",
                    confirmText: "ตกลง",
                    cancelText: "ยกเลิก",
                    opacityClose: true,
                    opacity: true
                }); 

                dispatch(setAuthenBalance({...reduxAuth, credit: result.credit}));
                loadData((reduxOrderList.data.length + 10), -1);
            }
            else {
                const message = data?.message;

                setAlertData({
                    title: "แจ้งเตือน",
                    show: true,
                    status: "error",
                    message: message,
                    type: "text",
                    action: "confirm",
                    confirmText: "ตกลง",
                    cancelText: "ยกเลิก",
                    opacityClose: true,
                    opacity: true
                }); 
            }
        });
    };

    useEffect(() => {
        if(alertData.confirmStatus) {
            requestApi(ticketId);
        }
    }, [alertData]);

    return (
        bodyData.length > 0 ? 
            bodyData.map((item, index) => (
                typeTable === "notReleased" ? 
                <div key={index} className={orderListStyle.listGrid} style={{ fontFamily: "Sarabun" }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={8}>
                            <div className={orderListStyle.listTitle}>
                                รายการ #{ item.ticket } { item.lottery_name }
                            </div>
                            <div className={orderListStyle.listDate}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        {
                                            dateFormatThai(timeZone(item.time_create, "date"))
                                        }
                                    </Grid>
                                    <Grid item>
                                        {
                                            `${timeZone(item.time_create, "time")} น.`
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={orderListStyle.listAmount}>
                                เงินเดิมพัน <span className={orderListStyle.listAmountNumber}>{ currencyFormat(item.bet_amount, 2) }</span> บาท
                            </div>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Grid container justifyContent="center">
                                <Grid item xs={6}>
                                    <div className="center" onClick={() => openResult(item)}>
                                        <RemoveRedEyeIcon className={[orderListStyle.tableIcon, orderListStyle.iconBgRegister]}></RemoveRedEyeIcon>
                                        <div className={orderListStyle.listBtnText}>
                                            ดูโพย
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="center" onClick={() => cencelOrder(item)}>
                                        <ReplayIcon className={[orderListStyle.tableIcon, orderListStyle.iconBgDanger]}></ReplayIcon>
                                        <div className={orderListStyle.listBtnText}>
                                            คืนโพย
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                :
                <div key={index} className={orderListStyle.listGrid} style={{ fontFamily: "Sarabun" }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={8} md={9}>
                            <div className={orderListStyle.listTitle}>
                                รายการ #{ item.ticket } { item.lottery_name }
                            </div>
                            <div className={orderListStyle.listDate}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        {
                                            dateFormatThai(timeZone(item.time_create, "date"))
                                        }
                                    </Grid>
                                    <Grid item>
                                        {
                                            `${timeZone(item.time_create, "time")} น.`
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={[orderListStyle.listAmount, orderListStyle.textDarkGray].join(" ")}>
                                เงินเดิมพัน {item.status === 7 ? "คืนเงิน" : ""} <span className={orderListStyle.listAmountNumber}>{ currencyFormat(item.bet_amount, 2) }</span> บาท
                            </div>
                            <div className={[orderListStyle.listAmount, orderListStyle.textDarkGray].join(" ")}>
                                ผลชนะ <span className={orderListStyle.listAmountWin}>{ currencyFormat(item.win_amount, 2) }</span> บาท
                            </div>
                        </Grid>
                        <Grid item xs={4} md={3}>
                            <Grid container justifyContent="flex-end" alignItems="center">
                                <Grid item xs={7} md={6}>
                                {
                                    renderStatus(item)
                                }
                                </Grid>
                                <Grid item xs={5} md={6}>
                                    <div className="center" onClick={() => openResult(item)}>
                                        <RemoveRedEyeIcon className={[orderListStyle.tableIcon, orderListStyle.iconBgRegister]}></RemoveRedEyeIcon>
                                        <div className={orderListStyle.listBtnText}>
                                            ดูโพย
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <div className={orderListStyle.listAmount}>
                                ผลชนะ <span className={orderListStyle.listAmountWin}>{ currencyFormat(item.win_amount, 2) }</span> บาท
                            </div>
                        </Grid>
                        <Grid item>
                            {
                                renderStatus(item)
                            }
                        </Grid>
                    </Grid> */}
                </div>
            ))
            :
            <div className={["center", orderListStyle.dataNull].join(" ")}>
                ไม่มีข้อมูล
            </div>
        // <table className={orderListStyle.tableSize}>
        //     <thead>
        //         <tr>
        //             {
        //                 headData.map((item, index) => (
        //                     <th className={bodyData.length > 0 ? orderListStyle.theadSize : orderListStyle.theadSizeBorder} key={index} style={{ textAlign: `${item.align}`, width: `${item.width}` }}>{item.text}</th>
        //                 ))
        //             }
        //         </tr>
        //     </thead>
        //     <tbody>
        //         {
        //             bodyData.length > 0 ?
        //             bodyData.map((item, index) => (
        //                 typeTable === "notReleased" ?
        //                 <tr key={item.id} className={[orderListStyle.tableList, (index % 2 === 0) ? orderListStyle.listGrey : orderListStyle.listDefault].join(" ")}>
        //                     <td className={orderListStyle.listTdPadding} style={{ textAlign: "left" }}>
        //                         {
        //                             item.lottery_name
        //                         }
        //                     </td>
        //                     <td style={{ textAlign: "left" }}>
        //                         <Grid container>
        //                             <Grid item xs={12} sm={5}>
        //                                 {
        //                                     dateFormatThai(timeZone(item.time_create, "date"))
        //                                 }
        //                             </Grid>
        //                             <Grid item xs={12} sm={6}>
        //                                 {
        //                                     `${timeZone(item.time_create, "time")} น.`
        //                                 }
        //                             </Grid>
        //                         </Grid>
        //                     </td>
        //                     <td className="textBoldHight" style={{ textAlign: "right" }}>
        //                         {
        //                             currencyFormat(item.bet_amount, 2)
        //                         }
        //                     </td>
        //                     <td style={{ textAlign: "center" }}>
        //                         <RemoveRedEyeIcon className={[orderListStyle.tableIcon, orderListStyle.iconBgRegister]} onClick={() => openResult(item)}></RemoveRedEyeIcon>
        //                     </td>
        //                     <td style={{ textAlign: "center" }}>
        //                         <ReplayIcon className={[orderListStyle.tableIcon, orderListStyle.iconBgDanger]} onClick={() => cencelOrder(item)}></ReplayIcon>
        //                     </td>
        //                 </tr>
        //                 :
        //                 <tr key={item.id} className={[orderListStyle.tableList, (index % 2 === 0) ? orderListStyle.listGrey : orderListStyle.listDefault].join(" ")}>
        //                     <td className={orderListStyle.listTdPadding} style={{ textAlign: "left" }}>
        //                         {
        //                             item.lottery_name
        //                         }
        //                     </td>
        //                     <td style={{ textAlign: "left" }}>
        //                         <Grid container>
        //                             <Grid item xs={12} sm={5}>
        //                                 {
        //                                     dateFormatThai(timeZone(item.time_create, "date"))
        //                                 }
        //                             </Grid>
        //                             <Grid item xs={12} sm={6}>
        //                                 {
        //                                     `${timeZone(item.time_create, "time")} น.`
        //                                 }
        //                             </Grid>
        //                         </Grid>
        //                     </td>
        //                     <td className="textBoldHight" style={{ textAlign: "right" }}>
        //                         {
        //                             currencyFormat(item.bet_amount, 2)
        //                         }
        //                     </td>
        //                     <td style={{ textAlign: "center" }}>
        //                         {
        //                             renderStatus(item)
        //                         }
        //                     </td>
        //                     <td className="textBoldHight" style={{ textAlign: "right" }}>
        //                         {
        //                             currencyFormat(item.win_amount, 2)
        //                         }
        //                     </td>
        //                     <td style={{ textAlign: "center" }}>
        //                         <RemoveRedEyeIcon className={[orderListStyle.tableIcon, orderListStyle.iconBgRegister]} onClick={() => openResult(item)}></RemoveRedEyeIcon>
        //                     </td>
        //                 </tr>
        //             ))
        //             :
        //             <tr>
        //                <td colSpan={12}>
        //                     <div className={["center", orderListStyle.dataNull].join(" ")}>
        //                         ไม่มีข้อมูล
        //                     </div>
        //                </td> 
        //             </tr>
        //         }
        //     </tbody>
        // </table>
    );
}