import {
    SET_DATA_NAVBAR_SUB
} from '../../actions/app/navbarSub';

const initialState = {
    result: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_NAVBAR_SUB:
            return { ...state, result: action.payload.items };
        default:
            return state;
    }
}