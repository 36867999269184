import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';

// Component
import Menu from "views/pages/home/component/Menu";
import List from "views/pages/home/component/List";

// CSS
import styles from "assets/css/Style.module.css";
import homeStyle from "views/pages/home/assets/Home.module.css";

import ImageShow from "views/components/ImageShow";
import buttonStyle from "assets/css/Button.module.css";

export default function ContentHome() {
    const processing = useSelector(state => state.reduxHome.processing);
    const reduxHome = useSelector(state => state.reduxHome.result);
    const reduxAppSetting = useSelector(state => state.reduxAppSetting);
    // const [homeData, setHomeData] = useState(null);
    const [homeAllList, setHomeAllList] = useState(null);
    const [yikiRound, setYikiRound] = useState(null);
    const [yikiRoundAll, setYikiRoundAll] = useState(0);
    const [yikiActive, setYikiActive] = useState(null);
    const history = useNavigate();

    const gotoYiki = () => {
        // if(yikiRound > 0) {
            history(`/yiki/?token=${reduxAppSetting.token}&app_id=${reduxAppSetting.app_id}&code=${reduxAppSetting.code}&language=${reduxAppSetting.lang}&redirect_url=${reduxAppSetting.redirect}`)
        // }
    };
    
    console.log(yikiActive)

    // useEffect(() => {
    //     setHomeData(reduxHome);
    // }, [processing, reduxHome])

    useEffect(() => {
        if (reduxHome !== null) {
            let homeList = [...reduxHome];
            // let allList = [];
            if (homeList !== null) {
                // console.log(homeList.slice(0,2))
                // console.log(homeList.slice(3))
                const arr1 = homeList.slice(0,2)
                const arr2 = homeList.slice(3)
                const allList = [...arr1, ...arr2];
                // allList.push(homeList.slice(0,2), homeList.slice(3))
                setHomeAllList(allList)
                // console.log("allList-> ", homeList)
            }
        }
    }, [processing, reduxHome, setHomeAllList])

    useEffect(() => {
        if (reduxHome !== null) {
            let homeDataList = [...reduxHome];
            let yikiList = []
            const rs1 = reduxHome.find(obj => {return obj.category_name === "หวยจับยี่กี"})
            // const rs = reduxHome.filter(obj => {return obj.category_name === "หวยจับยี่กี"})
            // console.log(homeDataList)
            // console.log(rs1)
            
            if (homeDataList !== null && (rs1 !== [] && rs1 !== undefined && rs1 !== null)) {
                // console.log(homeDataList[2])
                setYikiActive(reduxHome.find(obj => {return obj.category_name === "หวยจับยี่กี"}));
                // console.log(rs1)
            
                yikiList.push(homeDataList[2].lottery)
                const yikiStatus = [...yikiList[0]]
                // console.log(yikiList[0])
                const yikiCount = yikiStatus.filter(rnd => rnd.status === 2).length
                setYikiRound(yikiCount)
                setYikiRoundAll(rs1.lottery.length);
            }
        }
    }, [processing, reduxHome])

    // console.log(reduxHome)

    return (
        <>
            <div className={styles.boxMarginTop}>
                <Menu></Menu>
            </div>

            <div className={styles.boxMarginTop}>
                {
                    yikiActive !== null && yikiActive !== [] && yikiActive !== undefined ?
                        <div className={styles.boxMediumMarginBottom}>
                            <div className={[homeStyle.lottoryTitle, "textBoldHight"].join(" ")}>
                                หวยจับยี่กี
                            </div>

                            <div className={styles.boxMediumMarginTop}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={4} md={4}>
                                    <div className={[homeStyle.lottoCard, homeStyle.lottoCardActive].join(" ")} onClick={gotoYiki} style={{ opacity: yikiRound > 0 ? "1" : "0.6" }}>
                                        <div className={homeStyle.lottoCardImage}>
                                            {/* lotto_example */}
                                            <div className="relative">
                                                <ImageShow className={homeStyle.lottoImg} typeUrl="internal" src="/images/HYK-BG.webp"></ImageShow>
                                                
                                                <div className={homeStyle.lottoCardImagePosition}>
                                                    <ImageShow className={homeStyle.lottoCountryImg} typeUrl="external" src="/images/yiki.webp"></ImageShow>
                                                    
                                                    <div className={[homeStyle.lottoCardTitle, homeStyle.menuMargin].join(" ")}>
                                                        หวยจับยี่กี
                                                    </div>

                                                    <div className={[homeStyle.lottoCardText, homeStyle.menuMargin].join(" ")}>
                                                        {
                                                            yikiRound !== null && (
                                                                yikiRound > 0 ?
                                                                    <span>
                                                                        เปิดรับแทง {yikiRoundAll} รอบ
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        ปิดรับ : รอรอบถัดไป
                                                                    </span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={[homeStyle.lottoCardContent, "center"].join(" ")}>
                                        {
                                            yikiRound !== null && (
                                                yikiRound > 0 ? 
                                                <div className={[buttonStyle.btnOutLine, buttonStyle.outLineSuccess, homeStyle.btnFontSize].join(" ")}>
                                                    เปิดแทง
                                                </div>
                                                :
                                                <div className={[buttonStyle.btnOutLine, buttonStyle.outLineInfo, homeStyle.btnFontSize].join(" ")}>
                                                    ปิดแทง
                                                </div>
                                            )     
                                        }
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            </div>
                        </div>
                        :
                        <></>
                }

                {/* {console.log(homeData)} */}

                {
                    homeAllList !== null && (
                        homeAllList.map((item, index) => (
                            <div className={styles.boxMediumMarginBottom} key={index}>
                                <div className={[homeStyle.lottoryTitle, "textBoldHight"].join(" ")}>
                                    {
                                        item.category_name
                                    }
                                </div>

                                <div className={styles.boxMediumMarginTop}>
                                    <List category_name={item.category_name} dataList={item.lottery}></List>
                                </div>
                            </div>
                        ))
                    )
                }
            </div>
        </>
    );
}