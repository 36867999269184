// import { setCustomerToken } from "@core/auth/jwt/jwtService";
import { apiServices } from "@core/services/appService";

const SET_DATA_REWARD_CODE = 'app/SET_DATA_REWARD_CODE';
const SET_DATA_REWARD_LIST = 'app/SET_DATA_REWARD_LIST';

function loadRewardCode() {
    return dispatch => {
        apiServices({}, "get", "/py/lottery/code", null).then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 1) {
                const result = data.result;
                
                dispatch({
                    type: SET_DATA_REWARD_CODE,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_DATA_REWARD_CODE,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

function loadRewardList(myJson) {
    return dispatch => {
        apiServices(myJson, "post", "/py/lottery/history", null).then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 1) {
                const result = data.result;
                
                dispatch({
                    type: SET_DATA_REWARD_LIST,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_DATA_REWARD_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

export {
    loadRewardList,
    loadRewardCode,
    SET_DATA_REWARD_CODE,
    SET_DATA_REWARD_LIST
}