import {
    SET_RELOAD_SETTING
} from '../../actions/app/setting';

const initialState = {
    token: null,
    lang: null,
    app_id: null,
    redirect: null,
    code: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_RELOAD_SETTING:
            return { ...state, ...action.payload.items };
        default:
            return state;
    }
}