import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setBasketData } from "@core/redux/actions/basket/index";
import { setAuthenBalance } from "@core/redux/actions/auth/index";
import { setLoadding } from "@core/redux/actions/app/loadding";

// Component
import WrapperContainer from "views/components/WrapperContainer";

// Services
import { apiServices } from "@core/services/appService";

// CSS
import styles from "assets/css/Style.module.css";
import buttonStyle from "assets/css/Button.module.css";

export default function Footer({ CardContent, setAlertData, lottoryData, alertData, lottery_code }) {
    const reduxAuthData = useSelector(state => state.reduxAuth.result);
    const reduxLottoryData = useSelector(state => state.reduxLottory.result);
    const reduxAuth = {...reduxAuthData, ...reduxLottoryData};
    const reduxAppSetting = useSelector(state => state.reduxAppSetting);
    const [dataBalance, setDataBalance] = useState(0);
    const history = useNavigate();
    const dispatch = useDispatch();
    const [requestSuccess, setRequestSuccess] = useState(false);
    const [dataRequest, setDataRequest] = useState([]);
    const [requestAgain, setRequestAgain] = useState(false);
    const [requestWarning, setRequestWarning] = useState(false);

    const sendData = () => {
        let dataArr = dataRequest;
        let dataAmount = 0;
        let dataCount = dataArr.length;

        dataArr.forEach(element => {
            dataAmount = dataAmount + Number(element.bet);
        });

        if(dataCount > 0) {
            setAlertData({
                title: "ยืนยันส่งรายการโพย",
                show: true,
                status: null,
                message: `รายการทั้งหมด ${dataCount} รายการ <br /> รวมป็นจำนวนเงินทั้งหมด ${dataAmount} บาท`,
                type: "html",
                action: "sayYes",
                confirmText: "ส่งโพย",
                cancelText: "ยกเลิก",
                cancelStatus: false,
                opacityClose: true,
                opacity: true
            });
    
            setDataBalance(reduxAuth.credit - dataAmount);
        }
    };

    const setNewDataRequest = (item) => {
        let dataArr = lottoryData;
        const index = dataArr.findIndex(element => element.key === item.key);
        dataArr[index].is_cancel = item.is_cancel;
        dataArr[index].is_max = item.is_max;
        dataArr[index].is_max_number = item.is_max_number;
        dataArr[index].is_min = item.is_min;
        dataArr[index].is_price_rate = item.is_price_rate;
        dataArr[index].price = item.price;

        dispatch(setBasketData(dataArr));
    };
    
    const requestApi = () => {
        setRequestSuccess(false);
        setRequestAgain(false);
        setRequestWarning(false);
        let myJson = {
            "round_id": reduxAuth.lottery.round_id,
            "data" : dataRequest
        };

        dispatch(setLoadding({
            show: true,
            textProcess: "กำลังส่งโพยหวย กรุณารอสักครู่..."
        }));

        apiServices(myJson, "post", "/py/lottery/bet", reduxAuth?.token).then((data) => {
            dispatch(setLoadding({
                show: false,
                textProcess: null
            }));

            const statusCode = data.code;
            const result = data.result;

            if (statusCode === 200 || statusCode === 1) {
                setAlertData({
                    title: "แจ้งเตือน",
                    show: true,
                    status: "success",
                    message: "ส่งโพยหวยสำเร็จ !",
                    type: "text",
                    action: "confirm",
                    confirmText: "ตกลง",
                    cancelText: "ยกเลิก",
                    opacityClose: true,
                    opacity: true
                }); 

                dispatch(setAuthenBalance({...reduxAuth, credit: result.credit}));
                dispatch(setBasketData([]));
                setRequestSuccess(true);
                setRequestAgain(false);
                setRequestWarning(false);
            }
            else {
                const message = data?.message;
                const resData = result?.data;
                if(resData !== undefined) {
                    let dataFilter = resData.filter((item) => {
                        return item.is_cancel === true || item.is_max === true || item.is_max_number === true || item.is_min === true || item.is_price_rate === true;
                    });
                    
                    // let dataDuplicate = dataFilter.filter((value, index, self) => // ลบค่าซ้ำออก
                    //     index === self.findIndex((t) => (
                    //         t.code === value.code && t.number === value.number && t.is_cancel === value.is_cancel
                    //     ))
                    // );
                    
                    let textArr = "";
                    dataFilter.forEach((element) => {
                        if(element.is_cancel === true) {
                            setNewDataRequest(element);
                            textArr += `เลข ${element.number} ${element.name} ถูกยกเลิกการแทง<br>`;
                        }
                        else if(element.is_max === true) {
                            setNewDataRequest(element);
                            textArr += `เลข ${element.number} ${element.name}ใส่ราคาได้ไม่เกิน ${element.val_max} บาท <br>`;
                        }
                        else if(element.is_max_number === true) {
                            setNewDataRequest(element);
                            textArr += `ราคาตัวเลข ${element.number} ${element.name} ทั้งหมดรวมกันเกินค่าสูงสุด (ค่าสูงสุด ${element.val_max_number} บาท ต่อเลข) <br>`;
                        }
                        else if(element.is_min === true) {
                            setNewDataRequest(element);
                            textArr += `เลข ${element.number} ${element.name}ใส่ราคาเริ่มต้น ${element.val_min} บาท <br>`;
                        }
                        else if(element.is_price_rate === true) {
                            setNewDataRequest(element);
                            textArr += `เลข <span style='color: var(--bg-brand); font-weight: bold'>${element.number}</span> ${element.name} - อัตตราจ่ายมีการปรับเปลี่ยน - <span style='color: var(--bg-brand); font-weight: bold'>${element.val_price_rate}</span> บาท<br>`;
                        }
                    });
                    
                    let dataNew = resData.filter((item) => item.is_cancel !== true);
                    setDataRequest(dataNew);
                    setRequestWarning(true);
                    setAlertData({
                        title: message,
                        show: true,
                        status: null,
                        message: textArr,
                        type: "htmlUpdate",
                        action: "sayYes",
                        confirmText: "ยืนยัน",
                        cancelText: "ยกเลิก",
                        opacityClose: false,
                        opacity: true
                    });
                }
                else { 
                    setAlertData({
                        title: "แจ้งเตือน",
                        show: true,
                        status: "error",
                        message: message,
                        type: "text",
                        action: "confirm",
                        confirmText: "ตกลง",
                        cancelText: "ยกเลิก",
                        opacityClose: true,
                        opacity: true
                    }); 
                }
            }
        });
    };

    useEffect(() => {
        if(dataRequest.length > 0) {
            if(dataBalance < 0) {
                if(alertData.confirmStatus) {
                    setAlertData({
                        title: "แจ้งเตือน",
                        show: true,
                        status: "warning",
                        message: "เครดิตคงเหลือของคุณมีไม่เพียงพอ สำหรับทำรายการส่งโพยหวย",
                        type: "text",
                        action: "confirm",
                        confirmText: "ตกลง",
                        cancelText: "ยกเลิก",
                        cancelStatus: false,
                        opacityClose: true,
                        opacity: true
                    }); 
                }  
            }
            else {
                if(alertData.confirmStatus) {
                    requestApi();
                }
            }
        }
    }, [alertData, dataBalance]);

    useEffect(() => {
        if(alertData.show === false) {
            if(requestSuccess) {
                history(`/lottory/${lottery_code}?token=${reduxAppSetting.token}&app_id=${reduxAppSetting.app_id}&code=${lottery_code}&language=${reduxAppSetting.lang}&redirect_url=${reduxAppSetting.redirect}`);
            }
        }
    }, [alertData.show, requestSuccess]);

    useEffect(() => {
        setDataRequest(lottoryData);
    }, [lottoryData]);

    useEffect(() => {
        if(dataRequest.length > 0) {
            if(requestWarning) {
                setRequestAgain(true);
            }
        }
    }, [requestWarning]);

    useEffect(() => {
        if(dataRequest.length > 0) {
            if(requestAgain) {
                if(alertData.confirmStatus) {
                    requestApi();
                }
            }
        }
    }, [requestAgain]);

    return (
        <div className={styles.footerPosition}>
            <CardContent boxType="noRadius">
                <WrapperContainer>
                    <div className={[buttonStyle.btn, buttonStyle.btnConfirm].join(" ")} onClick={sendData}>
                        ส่งโพยหวย
                    </div>
                </WrapperContainer>
            </CardContent>
        </div>
    );
}