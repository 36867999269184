import {
    SET_DATA_YIKI,
    SET_YIKI_REWARD
} from "@core/redux/actions/yiki/index";

const initialState = {
    result: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_YIKI:
            return { ...state, result: action.payload.items };
        case SET_YIKI_REWARD:
            return { ...state, result: action.payload.items };
        default:
            return state;

    }
}