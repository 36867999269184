import React from 'react';
import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';

// Component
import { Grid, Dialog, Slide, AppBar, Toolbar, Typography } from '@mui/material';
import WrapperContainer from "views/components/WrapperContainer";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// CSS
import styles from "assets/css/Style.module.css";
import lottoryStyle from "views/pages/lottory/assets/Lottory.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Footer({ CardContent, lottery_code }) {
    const lottoryData = useSelector(state => state.reduxBasket.lottory);
    const reduxAppSetting = useSelector(state => state.reduxAppSetting);
    const history = useNavigate();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={styles.footerPosition}>
            <CardContent boxType="noRadius" style={{ boxShadow:" 0px 0px 2px rgba(0, 0, 0, 0.25)" }}>
                <WrapperContainer>
                    <div className={[lottoryStyle.boxFooter, "center"].join(" ")} onClick={() => history(`/basket?token=${reduxAppSetting.token}&app_id=${reduxAppSetting.app_id}&code=${lottery_code}&language=${reduxAppSetting.lang}&redirect_url=${reduxAppSetting.redirect}`)}>
                    {/* <div className={[lottoryStyle.boxFooter, "center"].join(" ")} onClick={handleClickOpen}> */}
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>รายการเเทงทั้งหมด</Grid>
                            <Grid item>
                                <Grid container alignItems="center" justifyContent="space-between" spacing={4}>
                                    <Grid item>
                                        <div className={[lottoryStyle.footerNumber, "textBoldHight textCLWarning"].join(" ")}>
                                            {
                                                lottoryData.length
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        รายการ
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    {/* <Dialog
                        fullScreen
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Transition}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    Sound
                                </Typography>
                                
                            </Toolbar>
                        </AppBar>
                    </Dialog> */}
                </WrapperContainer>
            </CardContent>
        </div>
    );
}