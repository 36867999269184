import { useState } from 'react';

// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";

// Component
import List from "views/pages/reward/component/List";

// CSS
import styles from "assets/css/Style.module.css";
import rewardStyle from "views/pages/reward/assets/Reward.module.css";
import SingleType from "views/pages/reward/component/SingleType";

export default function ReportType({ dataList, requestApi, setDataList, reduxRewardCode }) {
    const [dataCode, setDataCode] = useState([]);
    const cardPerTime = 10;
    const [next, setNext] = useState(cardPerTime);

    const handleCodeChange = (event) => {
        let value = event.target.value;
        if(value !== "") {
            requestApi(null, value, 100);
            setNext(cardPerTime);
        }
        else {
            setDataList([]);
        }
    };
    
    useEffectOnce(() => {
        setDataList([]);

        if(reduxRewardCode !== null) {
            if(reduxRewardCode?.data !== undefined && reduxRewardCode?.data !== null) {
                setDataCode(reduxRewardCode?.data);
            }
        }
    }, [reduxRewardCode]);

    return (
        <>
            <div className={rewardStyle.boxDate}>
                <select className={rewardStyle.selectCode} onChange={handleCodeChange}>
                    <option key="lottory_0" value="">เลือกประเภทหวย</option>
                    {
                        dataCode.map(item => (
                            <option key={item.id} value={item.code}>{item.name}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <SingleType dataList={dataList} next={next} setNext={setNext} cardPerTime={cardPerTime}></SingleType>
            </div>
        </>
    );
}