export default function Loadding({ textProcess }) {
    return (
        <div className="loadPage" style={{ zIndex: "9999" }}>
                <div className="loadderCenter">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="loadderAlign">
                            <div className="loader"></div>
                        </div>
                    </div>

                <div className="textLoadding center">
                    {
                        (textProcess !== undefined && textProcess !== null) ?
                        textProcess
                        :
                        "กำลังโหลดข้อมูล..."
                    }
                </div>
            </div>
        </div>
    );
}