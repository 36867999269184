const data = {
    headerType: "Bearer",
    authorization: "Authorization",
    contentType: "application/json",
    method_get: "get",
    method_post: "post",
    method_put: "put",
    method_delete: "delete"
};

export default data;
