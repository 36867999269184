// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";

// Redux
import { useDispatch } from 'react-redux';
import { setDataNavbarSub } from "@core/redux/actions/app/navbarSub";

// Component
import ContentReward from "./component/Content";

const RewardApp = () => {
    window.scrollTo(0, 0);
    const dispatch = useDispatch();

    useEffectOnce(() => {
        dispatch(setDataNavbarSub({
            typeContent: "navbarBack",
            textContent: "ผลรางวัล",
            redirect: "/",
            condition: false
        }));
    }, []);
    
    return (
        <ContentReward></ContentReward>
    );
}

export default RewardApp;