// Services
import { apiServices } from "@core/services/appService";

// Redux Action
import { SET_LOADDING } from "@core/redux/actions/app/loadding";

const SET_DATA_YIKI = 'app/SET_DATA_YIKI';
const SET_YIKI_REWARD = 'app/SET_YIKI_REWARD';

function loadYikiShootAll(myJson, token) {
    return dispatch => {
        apiServices({}, "get", `/py/lottery/yiki/shoot-number/${myJson}`, token).then((data) => {

            try {
                const statusCode = data.code;

                if (statusCode === 200 || statusCode === 1) {
                    const result = data.result;

                    dispatch({
                        type: SET_DATA_YIKI,
                        payload: {
                            items: result
                        }
                    })
                } else {
                    dispatch({
                        type: SET_DATA_YIKI,
                        payload: {
                            items: null
                        }
                    })
                }
            } catch (error) { }
            
        });
    }
}

function loadYikiShootReward(myJson, token) {
    return dispatch => {
        dispatch({
            type: SET_LOADDING,
            payload: {
                items: {
                    show: true,
                    textProcess: null
                }
            }
        });

        apiServices({}, "get", `/py/lottery/yiki/result/${myJson}`, token).then((data) => {
            dispatch({
                type: SET_LOADDING,
                payload: {
                    items: {
                        show: false,
                        textProcess: null
                    }
                }
            });

            try {
                const statusCode = data.code;

                if (statusCode === 200 || statusCode === 1) {
                    const result = data.result;

                    dispatch({
                        type: SET_YIKI_REWARD,
                        payload: {
                            items: {
                                result: result,
                                code: statusCode
                            }
                        }
                    })
                } else {
                    dispatch({
                        type: SET_YIKI_REWARD,
                        payload: {
                            items: {
                                result: null,
                                code: statusCode
                            }
                        }
                    })
                }
            } catch (error) { }
            
        });
    }
}

export {
    loadYikiShootAll,
    loadYikiShootReward,
    SET_DATA_YIKI,
    SET_YIKI_REWARD
}