import { useEffect, useState } from 'react';

// @Utility
import validateInput from "@utility/app/validateInput";
import currencyFormat from "@utility/numbers/currencyFormat";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setBasketData } from "@core/redux/actions/basket/index";

// Component
import Grid from '@mui/material/Grid';

// CSS
import styles from "assets/css/Style.module.css";
import formStyles from "assets/css/Input.module.css";
import buttonStyle from "assets/css/Button.module.css";
import basketStyle from "views/pages/basket/assets/Basket.module.css";

// Icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function InputAmount({ lottoryData, dataUpdate, dataLottoryList, setDataLottoryList, duplicateStatus, setDuplicateStatus, setDuplicateList, duplicateList, alertModalTxt, alertTxt }) {
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const [betAmount, setBetAmount] = useState(0);
    const [balanceAmount, setBalanceAmount] = useState(0);
    const dispatch = useDispatch();
    const [money, setMoney] = useState("0.00");
    const priceArr = [5, 10, 20, 50, 100];

    const deleteAll = () => {
        dispatch(setBasketData([]));
    };

    const handleInput = (e) => {
        let number = e.target.value;
        let dataArr = [];
        let dataArrMax = [];
        setMoney(number);
        lottoryData.forEach(element => {
            if(number > element.max) {
                dataArr = [...dataArr, {...element, bet: element.max}];
                dataArrMax = [...dataArrMax, element];
            }
            else {
                if(number <= element.min || number === "") {
                    dataArr = [...dataArr, {...element, bet: element.min}];
                }
                else {
                    dataArr = [...dataArr, {...element, bet: Number(number)}];
                }
                
            }
        });

        if(dataArrMax.length > 0) {
            let textArr = "";
            dataArrMax.forEach((element) => {
                textArr += `เลข ${element.number} ${element.name}ใส่ราคาได้ไม่เกิน ${element.max} บาท <br>`
            });

            setMoney("");
            alertModalTxt("แจ้งเตือน", true, null, textArr, "html", "confirm", "ตกลง", "", false, true);
        }

        dispatch(setBasketData(dataArr));
    };

    const priceAmount = (number) => {
        let dataArr = [];
        lottoryData.forEach(element => {
            dataArr = [...dataArr, {...element, bet: number}];
        });

        dispatch(setBasketData(dataArr));
        duplicateNumber(false);
    };

    const resetNumber = () => {
        duplicateNumber(false);
        if(money === 0 || money === "0.00") {
            setMoney("");
        }
    };

    const cutDuplicateNumber = () => {
        let dataArr = lottoryData;
        let dataArrNew = dataArr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.code === value.code && t.number === value.number
            ))
        );

        dispatch(setBasketData(dataArrNew));
        setDataLottoryList(dataArrNew);
    };

    const duplicateNumber = (status) => {
        setDuplicateStatus(status);

        if(status) {
            let dataArrNew = [];
            // let dataDuplicate = [];
            let dataDuplicateData = dataLottoryList.filter((val, i, self) => self.findIndex(k=>k.code === val.code && k.number === val.number) !== i);

            const lookup = dataLottoryList.filter((item) => {
                let dataNew = dataDuplicateData.filter((item2) => (item.code === item2.code && item.number === item2.number));

                return dataNew.length > 0;
            });
              
            dataArrNew = lookup;
            if(dataArrNew.length > 0) {
                setDataLottoryList(dataArrNew);
            }
            else {
                setDataLottoryList(lottoryData);
            }
        }
        else {
            setDataLottoryList(lottoryData);
        }
    };

    useEffect(() => {
        if(reduxAuth !== null) {
            if(lottoryData !== null) {
                let dataArr = lottoryData;
                let dataAmount = 0;
                let dataBalance = 0;
    
                dataArr.forEach(element => {
                    dataAmount = dataAmount + Number(element.bet);
                });
    
                dataBalance = reduxAuth.credit - dataAmount;
                setBetAmount(dataAmount);

                if(dataBalance < 0) {
                    alertTxt("", false, "", "");
                    alertTxt("แจ้งเตือน", true, "error", `เครดิตคงเหลือไม่เพียงพอ สำหรับทำรายการ`);
                }

                setBalanceAmount(dataBalance);
            }
            else {
                setBalanceAmount(reduxAuth.credit);
            }
        }
        // console.log("lottoryData ->",lottoryData);
    }, [dataUpdate, lottoryData, reduxAuth, setBetAmount]);

    // console.log("dataUpdate ->", dataUpdate);
    

    useEffect(() => {
        if(lottoryData !== null) {
            if(duplicateStatus && duplicateList.length > 0) {
                duplicateNumber(duplicateStatus);
            }
        }
    }, [dataUpdate, lottoryData, duplicateStatus]);

    return (
        <div className={basketStyle.boxInputAmount}>
             <div className={basketStyle.inputAmountPadding}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        {
                            duplicateStatus ?
                            <div className={[buttonStyle.btn, buttonStyle.outLineSuccess, buttonStyle.btnBorderActive, buttonStyle.btnPaddingOther].join(" ")} onClick={() => duplicateNumber(!duplicateStatus)}>
                                <span className={basketStyle.duplicateIconAlign}>
                                    <CheckCircleIcon className={basketStyle.duplicateIconSize}></CheckCircleIcon> 
                                </span>

                                ดูเลขซ้ำ
                            </div>
                            :
                            <div className={[buttonStyle.btn, buttonStyle.outLineSuccess, buttonStyle.btnPaddingOther].join(" ")} onClick={() => duplicateNumber(!duplicateStatus)}>
                                ดูเลขซ้ำ
                            </div>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <div className={[buttonStyle.btn, buttonStyle.outLineRegister, buttonStyle.btnPaddingOther].join(" ")} onClick={cutDuplicateNumber}>
                            ตัดเลขซ้ำ
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={[buttonStyle.btn, buttonStyle.outLineBrand, buttonStyle.btnPaddingOther].join(" ")} onClick={deleteAll}>
                            ลบทั้งหมด
                        </div>
                    </Grid>
                </Grid>
            </div>
            <hr />
            <div className={basketStyle.inputAmountPadding}>
                <div className={basketStyle.listTitle}>
                    เดิมพันราคา "เท่ากัน" ทั้งหมด
                </div>
                <div className={styles.boxMediumMarginTop}>
                    <input type="number" className={[formStyles.inputStyles, formStyles.inputBlack, formStyles.inputSize, "Sarabun"].join(" ")} placeholder="ระบุจำนวนเงิน" value={money} onKeyPress={(event) => validateInput(event, "numberDecimal")} pattern="[0-9]*" onChange={handleInput}   onClick={resetNumber} required />
                </div>
                <div className={styles.boxMediumMarginTop}>
                    <Grid container>
                        {
                            priceArr.map((item, index) => (
                                <Grid item key={index}>
                                    <div className={basketStyle.moneySelect} onClick={() => priceAmount(item)}>
                                        { item } ฿
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            </div>
            <div className={[styles.boxMediumMarginTop, "center"].join(" ")}>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={basketStyle.resultCredit}>
                            เครดิตคงเหลือ
                            <div className={basketStyle.resultNumber}>
                                {
                                    currencyFormat(balanceAmount, 2)
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={basketStyle.resultAmount}>
                            ยอดเดิมพัน
                            <div className={basketStyle.resultNumber}>
                                {
                                    currencyFormat(betAmount, 2)
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}