import { 
    SET_DATA_REWARD_LIST,
    SET_DATA_REWARD_CODE
} from "@core/redux/actions/reward/index";

const initialState = {
    result: null,
    resultCode: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_REWARD_LIST:
            return { ...state, result: action.payload.items };
        case SET_DATA_REWARD_CODE:
            return { ...state, resultCode: action.payload.items };
        default:
            return state;
    }
}