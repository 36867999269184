import { 
    SET_AUTHEN_DATA,
} from "@core/redux/actions/auth/index";

const initialState = {
    result: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_AUTHEN_DATA:
            return { ...state, result: action.payload.items };
        default:
            return state;
    }
}