const SET_TIME_CHECK = 'app/SET_TIME_CHECK';
const CLEAR_TIME_CHECK = 'app/CLEAR_TIME_CHECK';

function checkCloseRound(val) {
    return dispatch => {
        if (val < 1) {
            const result = true;

            dispatch({
                type: SET_TIME_CHECK,
                payload: {
                    items: result
                }
            });
        } else if (val >= 3) {
            dispatch({
                type: SET_TIME_CHECK,
                payload: {
                    items: null
                }
            });
        }
    }
}

function clearTimeCheck() {
    return async dispatch => {
        dispatch({
            type: CLEAR_TIME_CHECK,
            payload: {
                items: null
            }
        })
    }
}

export {
    checkCloseRound,
    clearTimeCheck,
    SET_TIME_CHECK,
    CLEAR_TIME_CHECK
}