import Grid from '@mui/material/Grid';

// @Utility
import currencyFormat from "@utility/numbers/currencyFormat";

// Component
import ReloadBlance from "views/components/ReloadBlance";

// CSS
import navbarStyle from "assets/css/Navbar.module.css";

export default function Credit({ reduxAuth }) {

    return (
        <div className={navbarStyle.navbar}>
            <Grid container spacing={1} alignItems="center" justifyContent={"flex-end"}>
                <Grid item>
                    <div className={navbarStyle.creditAlign}>
                        <div className="relative center">
                            <div className={navbarStyle.creditTextSize}>
                                {
                                    reduxAuth !== null && (
                                        currencyFormat(reduxAuth.credit, 2)
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item>
                    <div className="left">
                        <ReloadBlance></ReloadBlance>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}