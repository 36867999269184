import { useEffect, forwardRef, useState } from 'react';

// Component
import {
    Snackbar,
    AlertTitle
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

export default function SnackbarApp({ status, title, message, show, setSnackbarData }) {
    const [open, setOpen] = useState(show);
    const [state] = useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal } = state;

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarData({
            title: "",
            show: false,
            status: "",
            message: ""
        });

        setOpen(false);
    };

    useEffect(() => {
        let timer1 = setTimeout(() => {
            setSnackbarData({
                title: "",
                show: false,
                status: "",
                message: ""
            });

            setOpen(false);
        }, 6000);
        
        clearTimeout(timer1);
    });

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} style={{ textAlign: "left", fontSize: "16px" }}>
            <Alert severity={status} onClose={handleClose}>
                <AlertTitle>
                    <span className="alertTitle">
                        {title}
                    </span>
                </AlertTitle>
                <span className="kanit alertText">
                    {message}
                </span>
            </Alert>
        </Snackbar>
    );
}