import { useState } from 'react';
import moment from 'moment';
import { Thai } from "flatpickr/dist/l10n/th.js";

// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";

// Component
import Flatpickr from "react-flatpickr";
import Grid from '@mui/material/Grid';
import List from "views/pages/reward/component/List";

// CSS
import "flatpickr/dist/themes/material_green.css";
import styles from "assets/css/Style.module.css";
import rewardStyle from "views/pages/reward/assets/Reward.module.css";

import "moment/locale/th";

moment.locale("th");

export default function ReportDate({ dataList, requestApi, setDataList }) {
    const [selectedDate, setSelectedDate] = useState(moment().format("yyyy-MM-DD"));

    const handleDateChange = (value) => {
        const dataDate = value[0];
        setSelectedDate(moment(dataDate).format("yyyy-MM-DD"));
        requestApi(moment(dataDate).format("yyyy-MM-DD"), "", 100);
    };

    useEffectOnce(() => {
        setDataList([]);
        requestApi(moment().format("yyyy-MM-DD"), "", 100);
    }, []);

    return (
        <>
            <div className={rewardStyle.boxDate}>
                <Grid container alignItems="center">
                    <Grid item xs={4} sm={2}>
                        เลือกวันที่
                    </Grid>
                    <Grid item xs={8} sm={10}>
                        <div className="right">
                            <Flatpickr
                                value={selectedDate}
                                options={{ locale: Thai, disableMobile: true }}
                                onChange={handleDateChange}
                                className={rewardStyle.inputStyle}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div>
                <List dataList={dataList} selectedDate={selectedDate}></List>
            </div>
        </>
    );
}