import {
    SET_LOADDING
} from '../../actions/app/loadding';

const initialState = {
    show: false,
    textProcess: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOADDING:
            return { ...state, ...action.payload.items };
        default:
            return state;
    }
}