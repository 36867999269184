import { combineReducers } from "redux";

// Redux src
import reduxAuth from "@core/redux/reducers/auth/index";
import reduxAppNavbarSub from "@core/redux/reducers/app/navbarSub";
import reduxBasket from "@core/redux/reducers/basket/index";
import reduxOrderList from "@core/redux/reducers/orderList/index";
import reduxAppLoadding from "@core/redux/reducers/app/loadding";
import reduxReward from "@core/redux/reducers/reward/index";
import reduxAppSetting from "@core/redux/reducers/app/setting";
import reduxHome from "@core/redux/reducers/home/index";
import reduxLottory from "@core/redux/reducers/lottory/index";
import reduxYiki from "@core/redux/reducers/yiki/index";
import reduxTimecheck from "@core/redux/reducers/lottory/timecheck";

export default combineReducers({
    reduxAuth,
    reduxAppNavbarSub,
    reduxBasket,
    reduxOrderList,
    reduxAppLoadding,
    reduxReward,
    reduxAppSetting,
    reduxHome,
    reduxLottory,
    reduxYiki,
    reduxTimecheck
});