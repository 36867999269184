// Services
import { apiServices } from "@core/services/appService";

// Redux Action
import { SET_LOADDING } from "@core/redux/actions/app/loadding";

const SET_DATA_LOTTORY = 'app/SET_DATA_LOTTORY';

function loadLottoryAll(myJson, token) {
    return dispatch => {
        dispatch({
            type: SET_LOADDING,
            payload: {
                items: {
                    show: true,
                    textProcess: null
                }
            }
        });

        apiServices(myJson, "post", `/py/lottery/info`, token).then((data) => {
            dispatch({
                type: SET_LOADDING,
                payload: {
                    items: {
                        show: false,
                        textProcess: null
                    }
                }
            });

            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 1) {
                const result = data.result;
                
                dispatch({
                    type: SET_DATA_LOTTORY,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_DATA_LOTTORY,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

export {
    loadLottoryAll,
    SET_DATA_LOTTORY
}