import { useEffect, useState } from "react";

// Redux
import { useSelector } from 'react-redux';

// Component
import CardContent from "views/components/CardContent";
import Navbar from "views/pages/basket/component/Navbar";
import List from "views/pages/basket/component/List";
import InputAmount from "views/pages/basket/component/InputAmount";
import Footer from "views/pages/basket/component/Footer";
import Snackbar from "views/components/Snackbar";
import AlertModal from "views/components/Alert/index";

// CSS
import styles from "assets/css/Style.module.css";

export default function ContentBasket({ lottery_code }) {
    const lottoryData = useSelector(state => state.reduxBasket.lottory);
    const [dataUpdate, setDataUpdate] = useState(null);
    const [dataLottoryList, setDataLottoryList] = useState([]);
    const [duplicateStatus, setDuplicateStatus] = useState(false);
    const [duplicateList, setDuplicateList] = useState([]);
    const [snackbarData, setSnackbarData] = useState({
        title: "",
        show: false,
        status: "",
        message: ""
    }); 

    const [alertData, setAlertData] = useState({
        title: "",
        show: false,
        status: "",
        message: "",
        type: "",
        action: "",
        confirmText: "",
        cancelText: "",
        opacityClose: "",
        opacity: "",
        confirmStatus: false,
        cancelStatus: false
    }); 

    const alertTxt = (title, show, status, message) => {
        setSnackbarData({
            title,
            show, 
            status, 
            message
        });
    };

    const alertModalTxt = (title, show, status, message, type, action, confirmText, cancelText, opacityClose, opacity) => {
        setAlertData({
            title,
            show,
            status,
            message,
            type,
            action,
            confirmText,
            cancelText,
            opacityClose,
            opacity,
            confirmStatus: false,
            cancelStatus: false
        });
    };

    useEffect(() => {
        if(lottoryData !== null) {
            setDataLottoryList(lottoryData);
        }
    },[lottoryData]);

    return (
        <>
            {
                snackbarData.show && (
                    <Snackbar {...snackbarData} setSnackbarData={setSnackbarData}></Snackbar>
                )
            }

            {
                alertData.show && (
                    <AlertModal alertData={alertData} setAlertData={setAlertData}></AlertModal>
                )
            }

            <Navbar lottoryData={lottoryData}></Navbar>

            <div className={styles.boxPaddingBottom}>
                <div className={styles.boxMarginTop}>
                    <List 
                        lottoryData={lottoryData} 
                        setDataUpdate={setDataUpdate} 
                        dataUpdate={dataUpdate} 
                        setDataLottoryList={setDataLottoryList} 
                        dataLottoryList={dataLottoryList} 
                        duplicateStatus={duplicateStatus} 
                        duplicateList={duplicateList} 
                        alertTxt={alertTxt}
                    ></List>
                </div>

                <div className={styles.boxMarginTop}>
                    <CardContent boxType="radius" style={{ padding: "0px" }}>
                        <InputAmount 
                            lottoryData={lottoryData} 
                            dataUpdate={dataUpdate} 
                            setDataUpdate={setDataUpdate} 
                            setDataLottoryList={setDataLottoryList} 
                            dataLottoryList={dataLottoryList} 
                            setDuplicateStatus={setDuplicateStatus} 
                            duplicateStatus={duplicateStatus} 
                            setDuplicateList={setDuplicateList} 
                            duplicateList={duplicateList} 
                            alertTxt={alertTxt} 
                            alertModalTxt={alertModalTxt}
                        ></InputAmount>
                    </CardContent>
                </div>
            </div>

            <Footer 
                CardContent={CardContent} 
                setAlertData={setAlertData} 
                lottoryData={lottoryData} 
                alertData={alertData}
                lottery_code={lottery_code}
            ></Footer>
        </>
    );
}