import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";
import { setDataNavbarSub } from "@core/redux/actions/app/navbarSub";

// Redux
import { loadLottoryAll } from "@core/redux/actions/lottory/index";
import { useDispatch, useSelector } from 'react-redux';

// Component
import ContentLottory from "./component/Content";

const LottoryApp = () => {
    window.scrollTo(0, 0);
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const reduxLottory = useSelector(state => state.reduxLottory.result);
    const { code } = useParams();
    const dispatch = useDispatch();
    const history = useNavigate();

    useEffectOnce(() => {
        if(reduxAuth !== null) {
            let myJson = {
                "filter": {
                    "lottery_code": code
                }
            };

            dispatch(loadLottoryAll(myJson, reduxAuth?.token));
        }
    }, [reduxAuth, reduxLottory]);

    const lotteryCode = code.split("").splice(0,3).join("");
    const yikiPath = window.location.pathname.split("").splice(9,3).join("")

    useEffect(() => {
        if(reduxLottory !== null) {
            dispatch(setDataNavbarSub({
                typeContent: "navbarBack",
                textContent: reduxLottory.lottery.name,
                // redirect: `/`,
                redirect: yikiPath === "HYK" ? `/yiki` : `/`,
                // condition: lotteryCode === "HYK" ? false : true
                condition: true
            }));
        }
    }, [reduxLottory, dispatch]);

    return (
        reduxLottory !== null && (
            <ContentLottory reduxAuth={{...reduxAuth, ...reduxLottory}} lottery_code={code}></ContentLottory>
        )
    );
}

export default LottoryApp;