import { useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setAuthenBalance } from "@core/redux/actions/auth/index";

// Services
import { apiServices } from "@core/services/appService";

// Icon
import AutorenewIcon from '@mui/icons-material/Autorenew';

// CSS
import navbarStyle from "assets/css/Navbar.module.css";
import animationStyle from "assets/css/Animation.module.css";

export default function ReloadBlance() {
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const [spin, setSpin] = useState(false);
    const dispatch = useDispatch();

    const ReloadBlance = () => {
        setSpin(true);

        apiServices({}, "get", "/py/customer/credit", reduxAuth?.token).then((data) => {
            setSpin(false);
            const statusCode = data.code;
            const result = data.result;

            if (statusCode === 200 || statusCode === 1) {
                dispatch(setAuthenBalance({...reduxAuth, credit: result.credit}));
            }
        });
    };

    return <AutorenewIcon className={spin ? [navbarStyle.iconBalanceReloadSize, animationStyle.spin].join(" ") : [navbarStyle.iconBalanceReloadSize, animationStyle.refresh].join(" ")} onClick={ReloadBlance} />;
}