// import moment from 'moment';

// @Utility
// import timeZone from "@utility/app/timeZone";

// Services
import { apiServices } from "@core/services/appService";

// Redux Action
import { SET_LOADDING } from "@core/redux/actions/app/loadding";

const SET_AUTHEN_DATA = 'app/SET_AUTHEN_DATA';

function setAuthenBalance(data) {
    return {
        type: SET_AUTHEN_DATA,
        payload: {
            items: data
        }
    }
};

function loadDataAuthen(pathUrl, status, myJson) {
    return dispatch => {
        dispatch({
            type: SET_LOADDING,
            payload: {
                items: {
                    show: true,
                    textProcess: null
                }
            }
        });

        if(status) {
            if(pathUrl === "/reward") {
                dispatch({
                    type: SET_AUTHEN_DATA,
                    payload: {
                        items: undefined
                    }
                });
    
                dispatch({
                    type: SET_LOADDING,
                    payload: {
                        items: {
                            show: false,
                            textProcess: null
                        }
                    }
                });
            }
            else {
                apiServices(myJson, "post", "/py/auth/sign-in", null).then((data) => {
                    const statusCode = data.code;
                    const resData = data;
    
                    dispatch({
                        type: SET_LOADDING,
                        payload: {
                            items: {
                                show: false,
                                textProcess: null
                            }
                        }
                    });
        
                    if (statusCode === 200 || statusCode === 1) {
                        const result = data.result;
                        dispatch({
                            type: SET_AUTHEN_DATA,
                            payload: {
                                items: result
                            }
                        });
                    }
                    else {
                        dispatch({
                            type: SET_AUTHEN_DATA,
                            payload: {
                                items: {
                                    error: true,
                                    resData: resData
                                }
                            }
                        });
                    }
                });
            }
        }
        else {
            dispatch({
                type: SET_LOADDING,
                payload: {
                    items: {
                        show: false,
                        textProcess: null
                    }
                }
            });

            dispatch({
                type: SET_AUTHEN_DATA,
                payload: {
                    items: {
                        error: true,
                        resData: {
                            hideButton: true,
                            message: "ยืนยันโทเค็นไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
                        }
                    }
                }
            });
        }
    }
}

export {
    SET_AUTHEN_DATA,
    loadDataAuthen,
    setAuthenBalance
}