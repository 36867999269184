// Hooks
import useEffectOnce from "@utility/hooks/useEffectOnce";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { loadHomeList } from "@core/redux/actions/home/index";
import { setDataNavbarSub } from "@core/redux/actions/app/navbarSub";

// Component
import ContentHome from "./component/Content";

const HomeApp = () => {
    document.title = "หน้าหลัก";
    const reduxAuth = useSelector(state => state.reduxAuth.result);
    const dispatch = useDispatch();

    useEffectOnce(() => {
        dispatch(setDataNavbarSub({
            typeContent: "navbarText",
            textContent: "แทงหวยออนไลน์",
            redirect: null,
            condition: false
        }));

        if(reduxAuth !== null) {
            dispatch(loadHomeList({}, reduxAuth?.token));
        }
    }, [reduxAuth]);

    return (
        <ContentHome></ContentHome>
    );
}

export default HomeApp;